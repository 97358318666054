import { Col, Row } from "antd";
import Iframe from "../../../Common/Iframe";

const PanelRow = ({ title, timeSeriesURL, histogramURL, boxPlotURL }) => {
  return (
    <Row gutter={[12, 12]} className="pdtb05 mt1">
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <Iframe
          title={"Time Series - " + title}
          src={timeSeriesURL}
          width="100%"
          height="240"
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={7} xl={7}>
        <Iframe
          title={"Histogram - " + title}
          src={histogramURL}
          width="100%"
          height="240"
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={7} xl={7}>
        <Iframe
          title={"Box Plot - " + title}
          src={boxPlotURL}
          width="100%"
          height="240"
        />
      </Col>
    </Row>
  );
};

export default PanelRow;
