import { Card, Checkbox, Tooltip } from "antd";

const AddToCompare = ({ compareValue, value, updateCompareValue }) => {
  return (
    <Card className="ml05 add-compare">
      <Tooltip title="Add to compare">
        <Checkbox
          checked={compareValue?.includes(value)}
          onChange={(e) => updateCompareValue(value, e.target.checked)}
        >
          Add to Compare
        </Checkbox>
      </Tooltip>
    </Card>
  );
};

export default AddToCompare;
