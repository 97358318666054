import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Notification from "./components/Common/notification";
import { useOrganization } from "./OrganizationProvider";
import Routing from "./Routing";

const App = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const organization = useOrganization();

  useEffect(() => {
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = `${organization.s3BucketBaseUrl}/favicon.ico`;
    }
    document.title =
      organization.name || userDetails?.currentOrganisation?.name;
  }, [organization, userDetails?.currentOrganisation]);

  return (
    <BrowserRouter>
      <Notification />
      <Routing />
    </BrowserRouter>
  );
};
export default App;
