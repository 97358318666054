import { Card, Empty, Space, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatDateAndTime } from "../../../Common/Utils";
import {
  setSelectedTab,
  setPaginationDetails,
  setTestId,
  setTripSummary,
} from "../../../../reducers/testRideSlice";
import { getApis } from "../../../Common/apis";
import { vehicleTripSummaryUrl } from "../../../Common/endpoints";
import { UNITS } from "../../../Common/Constants";

const List = () => {
  const dispatch = useDispatch();
  const testRide = useSelector((state) => state.testRide);
  const paginationDetails = testRide?.paginationDetails;
  const testRideHistory = testRide?.testRideHistory;
  const totalElements = testRide?.totalElements;
  const authToken = localStorage.getItem("authToken");

  const handlePageChange = (pagination) => {
    dispatch(
      setPaginationDetails({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );
  };

  const getTripSummary = async (testId) => {
    const response = await getApis(
      vehicleTripSummaryUrl,
      { testId: testId },
      authToken
    );
    dispatch(setTripSummary(response?.data));
  };

  const columns = [
    {
      title: "Test#",
      dataIndex: "testId",
      key: "testId",
      width: "60px",
      align: "center",
    },
    {
      title: "Trip Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (startTime) => (
        <span className="pl1">{formatDateAndTime(startTime)}</span>
      ),
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
      render: (distance) => `${distance} ${UNITS.KM}`,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (duration) => `${duration} ${UNITS.HOURS}`,
    },
    {
      title: "Rider",
      dataIndex: "rider",
      key: "rider",
    },
    {
      title: "Location",
      dataIndex: "from",
      key: "from",
      render: (_, record) =>
        record.from && record.to ? `${record.from} - ${record.to}` : "NA",
    },
    {
      title: "Top Speed",
      dataIndex: "topSpeed",
      key: "topSpeed",
      render: (topSpeed) => `${topSpeed} ${UNITS.KM}`,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Link className="play" onClick={() => handleButtonClick(record)}>
            View
          </Link>
        </Space>
      ),
    },
  ];

  const handleButtonClick = (record) => {
    getTripSummary(record?.testId);
    dispatch(setTestId(record?.testId));
    dispatch(setSelectedTab("rideStatistics"));
  };

  return testRideHistory.length > 0 ? (
    <Table
      columns={columns}
      dataSource={testRideHistory}
      rowKey={(record) => record.testId}
      scroll={{ x: "100%" }}
      onChange={handlePageChange}
      pagination={{
        current: paginationDetails.current,
        pageSize: paginationDetails.pageSize,
        total: totalElements,
        showSizeChanger: false,
        showQuickJumper: false,
        hideOnSinglePage: true,
      }}
    />
  ) : (
    <Card>
      <div className="pd3025">
        <Empty />
      </div>
    </Card>
  );
};
export default List;
