import { useSelector } from "react-redux";
import { Card, Col, Progress, Row } from "antd";
import { UNITS } from "../../../../../Common/Constants";
import Iframe from "../../../../../Common/Iframe";

const ModeGrafana = ({ item }) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const eol = useSelector((state) => state.eol);
  const vehicleDetails = eol?.vehicleDetails;
  const response =
    Object.keys(eol?.dynoStop).length !== 0 ? eol?.dynoStop : eol?.dynoResult;

  const renderResult = (status) => {
    if (status === true) {
      return (
        <Progress
          type="circle"
          size={25}
          percent={100}
          strokeColor={{
            "0%": "#108ee9",
            "100%": "#87d068",
          }}
        />
      );
    } else if (status === false) {
      return (
        <Progress type="circle" size={25} percent={100} status="exception" />
      );
    } else {
      return "";
    }
  };

  const modeBasedFields = () => {
    if (item.mode === "ECO") {
      return {
        speed: response?.data?.maxSpeed?.eco,
        result: renderResult(response?.data?.threshold?.eco_test_passed),
        timeSeriesSrc: `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-mode1=${item.mode}&from=${response.startTime}&to=${response.stopTime}&panelId=2`,
        histogramSrc: `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-mode1=${item.mode}&from=${response.startTime}&to=${response.stopTime}&panelId=16`,
        boxPlotSrc: `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-mode1=${item.mode}&from=${response.startTime}&to=${response.stopTime}&panelId=17`,
      };
    } else if (item.mode === "CITY") {
      return {
        speed: response?.data?.maxSpeed?.city,
        result: renderResult(response?.data?.threshold?.city_test_passed),
        timeSeriesSrc: `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-mode2=${item.mode}&from=${response.startTime}&to=${response.stopTime}&panelId=13`,
        histogramSrc: `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-mode2=${item.mode}&from=${response.startTime}&to=${response.stopTime}&panelId=18`,
        boxPlotSrc: `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-mode2=${item.mode}&from=${response.startTime}&to=${response.stopTime}&panelId=19`,
      };
    } else if (item.mode === "POWER") {
      return {
        speed: response?.data?.maxSpeed?.power,
        result: renderResult(response?.data?.threshold?.power_test_passed),
        timeSeriesSrc: `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-mode3=${item.mode}&from=${response.startTime}&to=${response.stopTime}&panelId=14`,
        histogramSrc: `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-mode3=${item.mode}&from=${response.startTime}&to=${response.stopTime}&panelId=20`,
        boxPlotSrc: `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-mode3=${item.mode}&from=${response.startTime}&to=${response.stopTime}&panelId=21`,
      };
    } else if (item.mode === "REVERSE") {
      return {
        speed: response?.data?.maxSpeed?.reverse,
        result: renderResult(response?.data?.threshold?.reverse_test_passed),
        timeSeriesSrc: `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${response.startTime}&to=${response.stopTime}&panelId=3`,
        histogramSrc: `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${response.startTime}&to=${response.stopTime}&panelId=22`,
        boxPlotSrc: `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${response.startTime}&to=${response.stopTime}&panelId=23`,
      };
    }
  };

  const ModeCard = () => {
    return (
      <Card title={item?.mode} className="mt075">
        <Row gutter={[16, 16]} className="pdtb1">
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            Target: <b>{item.max}</b> {UNITS.KM_PER_HR}
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            Speed: <b>{modeBasedFields()?.speed?.toFixed(2)}</b>
            {UNITS.KM_PER_HR}
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>Result: {modeBasedFields()?.result}</Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            {vehicleDetails?.imeiNumber && (
              <Iframe
                title="Time Series"
                src={modeBasedFields()?.timeSeriesSrc}
                width="100%"
                height="270"
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={7} xl={7}>
            {vehicleDetails?.imeiNumber && (
              <Iframe
                title="Histogram"
                src={modeBasedFields()?.histogramSrc}
                width="100%"
                height="270"
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={7} xl={7}>
            {vehicleDetails?.imeiNumber && (
              <Iframe
                title="Boxplot"
                src={modeBasedFields()?.boxPlotSrc}
                width="100%"
                height="270"
              />
            )}
          </Col>
        </Row>
      </Card>
    );
  };

  return <ModeCard />;
};

export default ModeGrafana;
