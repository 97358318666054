import { useState } from "react";
import { Col, Row, Card } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { getGrafanaInterval } from "../../../Common/Utils";
import BRAKE from "../../../../assets/images/icons/brake.svg";
import Iframe from "../../../Common/Iframe";

const BrakeAnalytics = ({ tripSummary, summaryList }) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const summaryCount = summaryList?.length;
  const [view, setView] = useState(false);

  const onViewChange = () => {
    setView(!view);
  };

  const imeiURL =
    tripSummary?.imei?.length > 0
      ? "&var-imei=" + tripSummary.imei.join("&var-imei=")
      : "";
  const ridersURL =
    tripSummary?.riders?.length > 0
      ? "&var-riders=" + tripSummary.riders.join("&var-riders=")
      : "";
  const batteryURL =
    tripSummary?.battery?.length > 0
      ? "&var-battery=" + tripSummary.battery.join("&var-battery=")
      : "";

  const brakeDurationHistogramChart = `${
    process.env.REACT_APP_GRAFANA_PROXY_BASE_URL
  }/d-solo/bf83e667-6e25-4c6a-950c-e099f47757bf/test-drive-cumulative-left?orgId=${
    userDetails?.currentOrganisation?.id
  }${imeiURL}${ridersURL}${batteryURL}&var-interval=${getGrafanaInterval(
    tripSummary?.startTime,
    tripSummary?.stopTime
  )}&from=${tripSummary?.startTime}&to=${tripSummary?.stopTime}&panelId=7`;

  const brakeDurationBoxChart = `${
    process.env.REACT_APP_GRAFANA_PROXY_BASE_URL
  }/d-solo/bf83e667-6e25-4c6a-950c-e099f47757bf/test-drive-cumulative-left?orgId=${
    userDetails?.currentOrganisation?.id
  }${imeiURL}${ridersURL}${batteryURL}&var-interval=${getGrafanaInterval(
    tripSummary?.startTime,
    tripSummary?.stopTime
  )}&from=${tripSummary?.startTime}&to=${tripSummary?.stopTime}&panelId=8`;

  const BrakeDistanceHistogramChart = `${
    process.env.REACT_APP_GRAFANA_PROXY_BASE_URL
  }/d-solo/bf83e667-6e25-4c6a-950c-e099f47757bf/test-drive-cumulative-left?orgId=${
    userDetails?.currentOrganisation?.id
  }${imeiURL}${ridersURL}${batteryURL}&var-interval=${getGrafanaInterval(
    tripSummary?.startTime,
    tripSummary?.stopTime
  )}&from=${tripSummary?.startTime}&to=${tripSummary?.stopTime}&panelId=11`;

  const brakeDistanceBoxChart = `${
    process.env.REACT_APP_GRAFANA_PROXY_BASE_URL
  }/d-solo/bf83e667-6e25-4c6a-950c-e099f47757bf/test-drive-cumulative-left?orgId=${
    userDetails?.currentOrganisation?.id
  }${imeiURL}${ridersURL}${batteryURL}&var-interval=${getGrafanaInterval(
    tripSummary?.startTime,
    tripSummary?.stopTime
  )}&from=${tripSummary?.startTime}&to=${tripSummary?.stopTime}&panelId=12`;

  return (
    <Card
      onClick={onViewChange}
      className="cursor-pointer"
      title={
        <Row justify={"space-between"}>
          <Col className="align-center">
            <img src={BRAKE} alt="Brake" className="icon-size mr05" />
            Brake Analytics
          </Col>
          <Col className="font-weight-normal">
            View More
            {view ? (
              <UpOutlined className="ml05" />
            ) : (
              <DownOutlined className="ml05" />
            )}
          </Col>
        </Row>
      }
    >
      <Row gutter={[16, 16]} className="mt05" onClick={onViewChange}>
        <Col
          xs={24}
          sm={12}
          md={summaryCount >= 2 ? 24 : 5}
          lg={summaryCount >= 2 ? 12 : 6}
          xl={summaryCount >= 2 ? 12 : 6}
          xxl={summaryCount >= 2 ? 5 : 6}
        >
          Total Brakes: {tripSummary?.totalBrakes}{" "}
        </Col>
        <Col
          xs={24}
          sm={12}
          md={summaryCount >= 2 ? 24 : 5}
          lg={summaryCount >= 2 ? 12 : 6}
          xl={summaryCount >= 2 ? 12 : 6}
          xxl={summaryCount >= 2 ? 5 : 6}
        >
          Brakes/km: {tripSummary?.numberOfBrakesPerKm?.toFixed(2)}
        </Col>
        <Col
          xs={24}
          sm={12}
          md={summaryCount >= 2 ? 24 : 8}
          lg={summaryCount >= 2 ? 12 : 6}
          xl={summaryCount >= 2 ? 12 : 6}
          xxl={summaryCount >= 2 ? 8 : 6}
        >
          Avg Distance/Brake: {tripSummary?.averageBrakingDistance?.toFixed(2)}
        </Col>
        <Col
          xs={24}
          sm={12}
          md={summaryCount >= 2 ? 24 : 6}
          lg={summaryCount >= 2 ? 12 : 6}
          xl={summaryCount >= 2 ? 12 : 6}
          xxl={summaryCount >= 2 ? 6 : 6}
        >
          Avg Duration/Brake: {tripSummary?.averageBrakingDuration?.toFixed(2)}
        </Col>
      </Row>
      {view ? (
        <>
          <Row gutter={[16, 16]} className="mt2">
            <Col xs={24} lg={summaryCount >= 2 ? 24 : 12} xxl={12}>
              <Iframe
                title="Histogram - Cumulative Braking Duration"
                src={brakeDurationHistogramChart}
                width="100%"
                height="360"
              />
            </Col>
            <Col xs={24} lg={summaryCount >= 2 ? 24 : 12} xxl={12}>
              <Iframe
                title="Box Plot - Cumulative Braking Duration"
                src={brakeDurationBoxChart}
                width="100%"
                height="360"
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt1">
            <Col xs={24} lg={summaryCount >= 2 ? 24 : 12} xxl={12}>
              <Iframe
                title="Histogram - Cumulative Braking Distance"
                src={BrakeDistanceHistogramChart}
                width="100%"
                height="360"
              />
            </Col>
            <Col xs={24} lg={summaryCount >= 2 ? 24 : 12} xxl={12}>
              <Iframe
                title="Box Plot - Cumulative Braking Distance"
                src={brakeDistanceBoxChart}
                width="100%"
                height="360"
              />
            </Col>
          </Row>
        </>
      ) : null}
    </Card>
  );
};

export default BrakeAnalytics;
