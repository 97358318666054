import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  imeiList: [],
  vehicleDetails: {},
  connectivityStart: {},
  connectivityStop: {},
  dynoStart: {},
  dynoResult: {},
  dynoStop: {},
  testRunning: false,
  selectedTab: "connectivity",
  connectivityHistoryData: [],
  dynoHistoryData: [],
};

const eolSlice = createSlice({
  name: "eol",
  initialState,
  reducers: {
    reset: () => initialState,
    setImeiList: (state, action) => {
      return {
        ...state,
        imeiList: action.payload,
      };
    },
    setVehicleDetails: (state, action) => {
      return {
        ...state,
        vehicleDetails: action.payload,
      };
    },
    setConnectivityStart: (state, action) => {
      return {
        ...state,
        connectivityStart: action.payload,
      };
    },
    setConnectivityStop: (state, action) => {
      return {
        ...state,
        connectivityStop: action.payload,
      };
    },
    setDynoStart: (state, action) => {
      return {
        ...state,
        dynoStart: action.payload,
      };
    },
    setDynoResult: (state, action) => {
      return {
        ...state,
        dynoResult: action.payload,
      };
    },
    setDynoStop: (state, action) => {
      return {
        ...state,
        dynoStop: action.payload,
      };
    },
    setTestRunning: (state, action) => {
      return {
        ...state,
        testRunning: action.payload,
      };
    },
    setSelectedTab: (state, action) => {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },
    setConnectivityHistoryData: (state, action) => {
      return {
        ...state,
        connectivityHistoryData: action.payload,
      };
    },
    setDynoHistoryData: (state, action) => {
      return {
        ...state,
        dynoHistoryData: action.payload,
      };
    },
  },
});

export const {
  setImeiList,
  setVehicleDetails,
  setConnectivityStart,
  setConnectivityStop,
  setDynoStart,
  setDynoResult,
  setDynoStop,
  setTestRunning,
  reset,
  setSelectedTab,
  setConnectivityHistoryData,
  setDynoHistoryData,
} = eolSlice.actions;
export default eolSlice.reducer;
