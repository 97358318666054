import { Row, Col } from "antd";
import Panels from "./Panels";

const Connectivity = () => {
  return (
    <Row>
      <Col span={24}>
        <Panels />
      </Col>
    </Row>
  );
};

export default Connectivity;
