import { Col, Row } from "antd";
import CumulativeFilters from "./Filters";
import CumulativeOverview from "./Overview";
import SpeedMap from "./SpeedMap";
import SpeedAnalytics from "./Analytics/Speed";
import BatteryPerformance from "./Analytics/Battery";
import BrakeAnalytics from "./Analytics/Brake";

const SingleSummary = ({
  data,
  summaryList,
  tripSummary,
  updateSummaryList,
  updateTripSummary,
}) => {

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <CumulativeFilters
            data={data}
            summaryList={summaryList}
            tripSummary={tripSummary}
            updateSummaryList={updateSummaryList}
            updateTripSummary={updateTripSummary}
          />
        </Col>
      </Row>
      {tripSummary?.[data] ? (
        <Row>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <CumulativeOverview
                  summaryList={summaryList}
                  tripSummary={tripSummary?.[data]}
                />
                <SpeedMap
                  summaryList={summaryList}
                  tripSummary={tripSummary?.[data]}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} className="mt1">
              <Col span={24}>
                <SpeedAnalytics
                  summaryList={summaryList}
                  tripSummary={tripSummary?.[data]}
                />
              </Col>
              <Col span={24}>
                <BatteryPerformance
                  summaryList={summaryList}
                  tripSummary={tripSummary?.[data]}
                />
              </Col>
              <Col span={24}>
                <BrakeAnalytics
                  summaryList={summaryList}
                  tripSummary={tripSummary?.[data]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default SingleSummary;
