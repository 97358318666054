import { useSelector } from "react-redux";
import Timer from "../../../../../Common/Timer";

const DynoStartTest = ({ stopTest, startTest }) => {
  const eol = useSelector((state) => state.eol);
  const testRunning = eol?.testRunning;

  return (
    <>
      <Timer loading={testRunning} />
      {testRunning ? (
        <a href="#123" className="stop" onClick={stopTest}>
          Stop Test
        </a>
      ) : (
        <a href="#123" className="start" onClick={startTest}>
          Start Test
        </a>
      )}
    </>
  );
};

export default DynoStartTest;
