import { Empty, Space, Table } from "antd";
import { vehicleTestResultApiUrl } from "../../../../../Common/endpoints";
import { getApis } from "../../../../../Common/apis";
import { setConnectivityStop } from "../../../../../../reducers/eolSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  calculateDuration,
  formatDateAndTime,
} from "../../../../../Common/Utils";

const ConnectivityTests = () => {
  const dispatch = useDispatch();
  const connectivityHistoryData = useSelector(
    (state) => state.eol.connectivityHistoryData
  );
  const authToken = localStorage.getItem("authToken");

  const columns = [
    {
      title: "Test#",
      dataIndex: "iteration",
      key: "iteration",
    },
    {
      title: "Test Date, Time",
      dataIndex: "testDate",
      key: "testDate",
      render: (testDate) => formatDateAndTime(testDate),
    },
    {
      title: "Duration",
      dataIndex: "testDuration",
      key: "testDuration",
      render: (_, record) => calculateDuration(record),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Link className="play" onClick={() => handleButtonClick(record)}>
            View
          </Link>
        </Space>
      ),
    },
  ];

  const handleButtonClick = async (record) => {
    const response = await getApis(
      vehicleTestResultApiUrl,
      {
        testId: record?.testId,
        imei: record?.imei,
      },
      authToken
    );
    dispatch(setConnectivityStop(response?.data));
  };

  return connectivityHistoryData.length > 0 ? (
    <Table
      columns={columns}
      dataSource={connectivityHistoryData}
      rowKey={(record) => record.iteration}
      pagination={connectivityHistoryData.length > 3 ? { pageSize: 3 } : false}
      scroll={{ x: "100%" }}
    />
  ) : (
    <div className="pd3025">
      <Empty />
    </div>
  );
};
export default ConnectivityTests;
