import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Tabs } from "antd";
import { getApis } from "../../Common/apis";
import {
  vehicleDetailsApiUrl,
  imeiListApiUrl,
  vehicleTripSummaryUrl,
  testRideListUrl,
} from "../../Common/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { setVehicleDetails, setImeiList } from "../../../reducers/eolSlice";
import {
  setCurrentStep,
  setRideDetails,
  setSelectedTab,
  setTestId,
  setTripSummary,
  setPaginationDetails,
  setTestRideHistory,
  setRideHistoryFlag,
} from "../../../reducers/testRideSlice";
import RideHistory from "./RideHistory";
import RideStatistics from "./RideStatistics";
import { HistoryOutlined } from "@ant-design/icons";
import Loader from "../../Common/Loader";
import AddTestRide from "./AddRide";
import { formatDateAndTime } from "../../Common/Utils";

const TestRides = () => {
  const dispatch = useDispatch();
  const testRide = useSelector((state) => state.testRide);
  const selectedTab = testRide?.selectedTab;
  const tripSummary = testRide?.tripSummary;
  const testId = testRide?.testId;
  const [imei, setImei] = useState("");
  const authToken = localStorage.getItem("authToken");

  const paginationDetails = useSelector(
    (state) => state.testRide?.paginationDetails
  );

  const getRideHistoryData = useCallback(async () => {
    await getApis(
      testRideListUrl,
      {
        imei,
        page: paginationDetails.current - 1,
        size: paginationDetails.pageSize,
        sort: "id,desc",
      },
      authToken
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            setTestRideHistory({
              list: res?.data?.content,
              total: res?.data?.totalElements,
            })
          );
          dispatch(setRideHistoryFlag(null));
        }
      })
      .catch((e) => console.log(e));
  }, [authToken, dispatch, imei, paginationDetails]);

  useEffect(() => {
    if (imei) {
      getRideHistoryData();
    }
  }, [getRideHistoryData, imei]);

  const getImeiList = useCallback(async () => {
    const response = await getApis(
      `${imeiListApiUrl}?page=0&size=15&sort=asc`,
      {},
      authToken
    );
    dispatch(setImeiList(response?.data));
    setImei(response?.data[0]);
  }, [authToken, dispatch]);

  const getVehicleDetails = useCallback(async () => {
    const response = await getApis(
      vehicleDetailsApiUrl,
      {
        imei: imei,
      },
      authToken
    );
    dispatch(setVehicleDetails(response?.data));
    dispatch(setTestId(response?.data?.previousTests?.test_ride));
  }, [authToken, dispatch, imei]);

  const getTripSummary = useCallback(async () => {
    const response = await getApis(
      vehicleTripSummaryUrl,
      { testId: testId },
      authToken
    );
    if (response?.data?.tripModeStats && testRide?.rideHistoryFlag === testId) {
      getRideHistoryData();
    }
    dispatch(setTripSummary(response?.data));
  }, [
    authToken,
    dispatch,
    testId,
    getRideHistoryData,
    testRide?.rideHistoryFlag,
  ]);

  useEffect(() => {
    let intervalId;
    if (Object.keys(tripSummary).length === 0 && testId && testId !== 0) {
      getTripSummary();
    } else if (tripSummary && tripSummary?.message === "IN_PROGRESS") {
      intervalId = setInterval(() => {
        getTripSummary();
      }, 5000);
    }

    return () => clearInterval(intervalId);
  }, [getTripSummary, testId, tripSummary]);

  useEffect(() => {
    getImeiList();
  }, [getImeiList]);

  useEffect(() => {
    if (imei !== "") {
      getVehicleDetails();
    }
  }, [getVehicleDetails, imei]);

  const onChangeImei = (value) => {
    setImei(value);
    dispatch(setVehicleDetails({}));
    dispatch(setSelectedTab(selectedTab));
    dispatch(setCurrentStep(0));
    dispatch(setRideDetails({}));
    dispatch(setTestId(0));
    dispatch(setTripSummary({}));
    dispatch(setPaginationDetails({ current: 1 }));
  };

  const onChangeTab = (key) => {
    dispatch(setSelectedTab(key));
  };

  const items = [
    {
      key: "addTestRide",
      label: "Add Test Ride",
      children: (
        <AddTestRide
          getRideHistoryData={getRideHistoryData}
          onChangeImei={onChangeImei}
          imei={imei}
        />
      ),
    },
    {
      key: "rideHistory",
      label: "Ride History",
      children: <RideHistory onChangeImei={onChangeImei} imei={imei} />,
    },
    {
      key: "rideStatistics",
      label: "Ride Statistics",
      children:
        tripSummary?.message === "IN_PROGRESS" ? (
          <Loader text={"Generating"} />
        ) : (
          <RideStatistics onChangeImei={onChangeImei} imei={imei} />
        ),
    },
  ];

  const renderLastRecordedTime = () => {
    if (selectedTab === "rideStatistics") {
      return (
        <div className="mobile-hide">
          <HistoryOutlined className="mr05" />
          {formatDateAndTime(tripSummary?.recordedTime)}
        </div>
      );
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Tabs
          activeKey={selectedTab}
          items={items}
          onChange={onChangeTab}
          tabBarExtraContent={renderLastRecordedTime()}
        />
      </Col>
    </Row>
  );
};

export default TestRides;
