import React, { useState, useEffect } from "react";
import { Typography } from "antd";

const { Text } = Typography;

const Timer = (props) => {
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    if (props.loading) {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown + 1);
      }, 1000);
      return () => {
        clearInterval(interval);
        setCountdown(0);
      };
    }
  }, [props.loading]);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <Text
      className={props.className}
      style={{ marginRight: "1rem", fontSize: "16px" }}
    >
      {formatTime(countdown)}
    </Text>
  );
};

export default Timer;
