import { Card, Col, Row } from "antd";
import { useSelector } from "react-redux";
import ModeTable from "./ModeTable";
import { HistoryOutlined } from "@ant-design/icons";
import { formatDateAndTime } from "../../../../../Common/Utils";
import VehicleDetails from "../../../../VehicleDetails";
import Iframe from "../../../../../Common/Iframe";

const Result = ({ onChangeImei, imei }) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const eol = useSelector((state) => state.eol);
  const vehicleDetails = eol?.vehicleDetails;
  const response = eol?.dynoResult;
  const testRunning = eol?.testRunning;

  const result_current_src = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${response.startTime}&to=${response.stopTime}&panelId=4`;
  const result_eco_src = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${response.startTime}&to=${response.stopTime}&panelId=15`;

  const running_current_src = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&refresh=5s&panelId=4`;
  const running_eco_src = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&refresh=5s&panelId=15`;

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={5} xl={5}>
            <VehicleDetails onChangeImei={onChangeImei} imei={imei} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={19} xl={19}>
            <Card
              title="Dyno Result"
              bodyStyle={{ padding: "4px", minHeight: "243px" }}
              extra={
                response?.startTime && (
                  <div className="fs13">
                    <HistoryOutlined />
                    <span className="card-sub-text ml05">
                      {formatDateAndTime(response?.startTime)}
                    </span>
                  </div>
                )
              }
            >
              <ModeTable />
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="mt1">
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {vehicleDetails?.imeiNumber && (
              <Iframe
                title="Current"
                src={testRunning ? running_current_src : result_current_src}
                width="100%"
                height="220"
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {vehicleDetails?.imeiNumber && (
              <Iframe
                title="Speed"
                src={testRunning ? running_eco_src : result_eco_src}
                width="100%"
                height="220"
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default Result;
