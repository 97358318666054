import React, { useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { useSelector } from "react-redux";
import { rolesToExclude } from "../Common/Utils";
import { jwtRegister } from "niche-user-fe-service";
import { userJwtRegisterUrl } from "../Common/endpoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FORMS, ROUTES } from "../Common/Constants";

const { Option } = Select;

const RegisterForm = () => {
  const roles = useSelector((state) => state.auth?.roles);
  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const [optionsSelected, setOptionsSelected] = useState([]);
  const [message, setMessage] = useState("");

  const handleChange = (value) => {
    setOptionsSelected(value);
  };

  const filteredRoles = roles.filter(
    (role) => !rolesToExclude().includes(role.name)
  );

  const onFinish = async (values) => {
    await jwtRegister(userJwtRegisterUrl, values, authToken)
      .then((response) => {
        navigate(ROUTES.PROFILE.link);
        toast.success(FORMS.ADD_USER.REQUIRED_FIELDS.SUCCESS);
      })
      .catch((error) => setMessage(error?.response?.data?.message));
  };

  return (
    <div>
      <div className="error">{message}</div>
      <Form
        name="register"
        block="true"
        onFinish={onFinish}
        className="register mt1"
        validateMessages={{
          required: FORMS.ADD_USER.REQUIRED_FIELDS.BLANK_ERROR,
        }}
      >
        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
            },
          ]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
          }}
        >
          <Input
            placeholder={FORMS.ADD_USER.REQUIRED_FIELDS.FIRST_NAME}
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
            },
          ]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "0px 0px 0 16px",
          }}
        >
          <Input
            placeholder={FORMS.ADD_USER.REQUIRED_FIELDS.LAST_NAME}
            size="large"
          />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
            },
            {
              type: "email",
              message: FORMS.ADD_USER.REQUIRED_FIELDS.INVALID_ERROR,
            },
          ]}
        >
          <Input
            placeholder={FORMS.ADD_USER.REQUIRED_FIELDS.EMAIL}
            size="large"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={FORMS.ADD_USER.REQUIRED_FIELDS.PASSWORD}
            size="large"
            type="password"
          />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={FORMS.ADD_USER.REQUIRED_FIELDS.PHONE}
            size="large"
          />
        </Form.Item>

        <Form.Item
          name="roleId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={FORMS.ADD_USER.REQUIRED_FIELDS.ROLE}
            mode="multiple"
            onChange={handleChange}
            size="large"
          >
            {filteredRoles.map((r, index) => {
              return (
                <Option
                  key={index}
                  disabled={
                    optionsSelected.length > 0
                      ? optionsSelected.includes(r.id)
                        ? false
                        : true
                      : false
                  }
                  value={r.id}
                >
                  {r.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Button htmlType="submit" className="ant-btn-submit mt1">
          {FORMS.ADD_USER.REQUIRED_FIELDS.SUBMIT}
        </Button>
      </Form>
    </div>
  );
};
export default RegisterForm;
