import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTab: "addTestRide",
  currentStep: 0,
  rideDetails: {},
  testId: 0,
  tripSummary: {},
  testRideHistory: [],
  rideHistoryFlag: null,
  totalElements: 0,
  paginationDetails: {
    current: 1,
    pageSize: 10,
  },
};

const testRideSlice = createSlice({
  name: "testRide",
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedTab: (state, action) => {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },
    setCurrentStep: (state, action) => {
      return {
        ...state,
        currentStep: action.payload,
      };
    },
    setRideDetails: (state, action) => {
      return {
        ...state,
        rideDetails: action.payload,
      };
    },
    setTestId: (state, action) => {
      return {
        ...state,
        testId: action.payload,
      };
    },
    setTripSummary: (state, action) => {
      return {
        ...state,
        tripSummary: action.payload,
      };
    },
    setRideHistoryFlag: (state, action) => {
      return {
        ...state,
        rideHistoryFlag: action.payload,
      };
    },
    setTestRideHistory: (state, action) => {
      return {
        ...state,
        testRideHistory: action.payload.list,
        totalElements: action.payload.total,
      };
    },
    setPaginationDetails: (state, action) => {
      return {
        ...state,
        paginationDetails: {
          ...state.paginationDetails,
          ...action.payload,
        },
      };
    },
  },
});

export const {
  setSelectedTab,
  setCurrentStep,
  setRideDetails,
  setTestId,
  setTripSummary,
  setTestRideHistory,
  setRideHistoryFlag,
  setPaginationDetails,
} = testRideSlice.actions;
export default testRideSlice.reducer;
