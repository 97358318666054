import { Card } from "antd";
import HIGHLIGHT from "../../../assets/images/icons/highlight.svg";
import { UNITS } from "../../Common/Constants";

const TripHighlight = ({ tripSummary }) => {

  const innerContent = (title, count, unit, className) => {
    return className ? (
      <div className={className}>
        <div className="fs12">{title}</div>
        <div className="mt1">
          <span className="avg-count">{count}</span>
          <span className="ml015">{unit}</span>
        </div>
      </div>
    ) : (
      <div>
        <div className="fs12">{title}</div>
        <div className="mt1">
          <span className="avg-count">{count}</span>
          <span className="card-sub-text ml015">{unit}</span>
        </div>
      </div>
    );
  };

  return (
    <Card
      title={
        <div className="fs14 left">
          <div className="flex-center">
            <img src={HIGHLIGHT} alt="km" className="icon-size mr05" />
            Highlights of all trips
          </div>
        </div>
      }
    >
      <Card.Grid className="grid border-right">
        {innerContent("Top Speed", tripSummary?.topSpeed?.toFixed(2), UNITS.KM)}
      </Card.Grid>
      <Card.Grid className="grid">
        {innerContent(
          "Max Acceleration",
          tripSummary?.maxAcceleration?.toFixed(2),
          UNITS.KM_PER_HR,
          "disabled"
        )}
      </Card.Grid>
      <Card.Grid className="grid border-right">
        {innerContent(
          "Max Deceleration",
          tripSummary?.maxDeceleration?.toFixed(2),
          UNITS.KM_PER_HR,
          "disabled"
        )}
      </Card.Grid>
      <Card.Grid className="grid">
        {innerContent(
          "Max Current",
          tripSummary?.maxCurrent?.toFixed(2),
          UNITS.MILLI_AMPERE,
          "disabled"
        )}
      </Card.Grid>
      <Card.Grid className="grid border-right">
        {innerContent(
          "Total Braking Time",
          tripSummary?.brakingTime?.toFixed(2),
          UNITS.SECONDS
        )}
      </Card.Grid>
      <Card.Grid className="grid">
        {innerContent(
          "Total Braking Distance",
          tripSummary?.brakingDistance?.toFixed(2),
          UNITS.METER
        )}
      </Card.Grid>
    </Card>
  );
};
export default TripHighlight;
