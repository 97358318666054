import { Col, Row, Select, Button, Tooltip } from "antd";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useState, useEffect, useCallback } from "react";
import DateTimePicker from "../../Common/DateTimePicker";
import {
  testRideCumulativeUrl,
  testRideFilterCountUrl,
  testRideFilterUrl,
} from "../../Common/endpoints";
import { postApis } from "../../Common/apis";
import Loader from "../../Common/Loader";

const CumulativeFilters = ({
  data,
  summaryList,
  tripSummary,
  updateSummaryList,
  updateTripSummary,
}) => {
  const [dateRange, setDateRange] = useState([]);
  const [loader, setLoader] = useState(false);
  const [dropDownList, setDropDownList] = useState({});
  const [selectedList, setSelectedList] = useState({});
  const [rideCount, setRideCount] = useState(null);
  const lastSummary = summaryList[summaryList.length - 1];
  const summaryCount = summaryList?.length;
  const authToken = localStorage.getItem("authToken");

  const onChangePicker = (value) => {
    setDateRange(value);
  };

  const removeSummary = () => {
    updateSummaryList(summaryList.filter((item) => item !== data));
    updateTripSummary({ ...tripSummary, [data]: null });
  };

  const addSummary = () => {
    updateSummaryList([...summaryList, lastSummary + 1]);
  };

  const onFilterApply = () => {
    setLoader(true);
    postApis(
      testRideCumulativeUrl,
      {
        startTime: Math.floor(dateRange?.[0]),
        endTime: Math.floor(dateRange?.[1]),
        imei: selectedList.imei,
        riders: selectedList.riders,
        batteryManufacturers: selectedList.battery,
      },
      authToken
    )
      .then((res) => {
        if (res.status === 200) {
          updateTripSummary({
            ...tripSummary,
            [data]: {
              ...res?.data,
              ...selectedList,
              startTime: Math.floor(dateRange?.[0]),
              stopTime: Math.floor(dateRange?.[1]),
            },
          });
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const onReset = () => {
    setSelectedList((prev) => ({
      ...prev,
      imei: dropDownList?.imei,
      riders: dropDownList?.riders?.map((i) => i.id),
      battery: dropDownList?.battery,
    }));
  };

  useEffect(() => {
    if (dateRange?.length > 0) {
      postApis(
        testRideFilterCountUrl,
        {
          startTime: Math.floor(dateRange?.[0]),
          endTime: Math.floor(dateRange?.[1]),
          imei: selectedList.imei,
          riders: selectedList.riders,
          batteryManufacturers: selectedList.battery,
        },
        authToken
      )
        .then((res) => {
          if (res.status === 200) {
            setRideCount(res?.data?.count);
          }
        })
        .catch((e) => console.log(e));
    }

    // eslint-disable-next-line
  }, [authToken, selectedList]);

  const getRideFilterData = useCallback(async () => {
    await postApis(
      testRideFilterUrl,
      {
        startTime: Math.floor(dateRange?.[0]),
        endTime: Math.floor(dateRange?.[1]),
        imei: selectedList.imei,
        riders: selectedList.riders,
      },
      authToken
    )
      .then((res) => {
        if (res.status === 200) {
          setDropDownList((prev) => ({
            ...prev,
            imei: res?.data?.imeiList || [],
            riders: res?.data?.riders || [],
            battery: res?.data?.batteryManufacturers || [],
          }));
          setSelectedList((prev) => ({
            ...prev,
            imei: res?.data?.imeiList || [],
            riders: res?.data?.riders.map((i) => i.id) || [],
            battery: res?.data?.batteryManufacturers || [],
          }));
        }
      })
      .catch((e) => console.log(e));

    // eslint-disable-next-line
  }, [authToken, dateRange]);

  useEffect(() => {
    if (dateRange?.length > 1) {
      getRideFilterData();
    }
  }, [getRideFilterData, dateRange]);

  const onFilterChange = (name, value) => {
    setSelectedList((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="cumulative-summary-filter-card">
      {loader ? <Loader text={"Generating"} /> : null}
      <Row justify="space-between" className="card-head">
        <Col className="fs1">Filters</Col>
        <Col>
          {summaryList.length === 1 ? (
            <Tooltip title={"Add New Test Ride Summary"}>
              <Button
                onClick={addSummary}
                type="primary"
                className="add-statistic-btn"
                shape="round"
                icon={<PlusCircleOutlined />}
                size="small"
              >
                Compare
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={"Close this Test Ride Summary"}>
              <Button
                onClick={removeSummary}
                type="primary"
                className="add-statistic-btn"
                shape="round"
                icon={<CloseCircleOutlined />}
                size="small"
              >
                Close
              </Button>
            </Tooltip>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mtb05 mlr0">
        <Col
          xs={24}
          sm={24}
          md={summaryCount >= 2 ? 24 : 14}
          lg={summaryCount >= 2 ? 12 : 10}
          xl={summaryCount >= 2 ? 12 : 5}
        >
          <div className="lable">Time Range</div>
          <DateTimePicker
            dates={dateRange}
            onChangePicker={onChangePicker}
            extraFilter={true}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={summaryCount >= 2 ? 24 : 10}
          lg={summaryCount >= 2 ? 12 : 14}
          xl={summaryCount >= 2 ? 12 : 5}
        >
          <div className="lable">IMEI Number</div>
          <Select
            allowClear
            mode="multiple"
            placeholder="IMEI"
            className="w100 imei-values"
            value={selectedList.imei}
            maxTagCount={"responsive"}
            onChange={(value) => onFilterChange("imei", value)}
          >
            {dropDownList?.imei?.map((value, i) => (
              <Select.Option key={i} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={summaryCount >= 2 ? 24 : 8}
          lg={summaryCount >= 2 ? 12 : 8}
          xl={summaryCount >= 2 ? 12 : 5}
          xxl={summaryCount >= 2 ? 9 : 5}
        >
          <div className="lable">Rider Name</div>
          <Select
            allowClear
            mode="multiple"
            placeholder="Rider name"
            className="w100"
            maxTagCount={"responsive"}
            value={selectedList.riders}
            onChange={(value) => onFilterChange("riders", value)}
          >
            {dropDownList?.riders?.map((value, i) => (
              <Select.Option key={i} value={value.id}>
                {value.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={summaryCount >= 2 ? 24 : 8}
          lg={summaryCount >= 2 ? 12 : 8}
          xl={summaryCount >= 2 ? 12 : 5}
          xxl={summaryCount >= 2 ? 9 : 5}
        >
          <div className="lable">Battery Manufacturer</div>
          <Select
            allowClear
            mode="multiple"
            placeholder="Battery manufacturer"
            className="w100"
            maxTagCount={"responsive"}
            value={selectedList.battery}
            onChange={(value) => onFilterChange("battery", value)}
          >
            {dropDownList?.battery?.map((value, i) => (
              <Select.Option key={i} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={summaryCount >= 2 ? 24 : 8}
          lg={summaryCount >= 2 ? 24 : 8}
          xl={summaryCount >= 2 ? 24 : 4}
          xxl={summaryCount >= 2 ? 6 : 4}
          className="align-end"
        >
          <Button
            className="back-btn"
            htmlType="button"
            style={{ width: "40%" }}
            onClick={onReset}
          >
            Reset
          </Button>
          <Button
            type="primary"
            className="ant-btn-submit"
            onClick={onFilterApply}
            disabled={!rideCount}
            style={{ width: "54%", marginLeft: "6%" }}
          >
            Apply
          </Button>
        </Col>
      </Row>
      <Row justify={"end"}>
        <Col>
          {rideCount !== null ? (
            <span className="ride-count"> Test Ride Count: {rideCount}</span>
          ) : (
            <span className="pd1" />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CumulativeFilters;
