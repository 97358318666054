import { Card, Col, Row } from "antd";
import TOTAL_KM from "../../../../assets/images/icons/totalkm.svg";
import DISTANCE_KM from "../../../../assets/images/icons/distance.svg";
import AVG_SPEED from "../../../../assets/images/icons/avg-speed.svg";
import RIDE_DURATION from "../../../../assets/images/icons/ride-duration.svg";
import STOP_DURATION from "../../../../assets/images/icons/stop-duration.svg";
import { useSelector } from "react-redux";
import { UNITS } from "../../../Common/Constants";

const Overview = () => {
  const tripSummary = useSelector((state) => state.testRide.tripSummary);

  const innerContent = (imgPath, title, count, unit) => {
    return (
      <Col xs={24} sm={24} md={4} lg={4} xl={4}>
        <Card
          title={
            <div className="overview-header">
              <img src={imgPath} alt="km" className="icon-size mr05" />
              {title}
            </div>
          }
        >
          <div className="mtb05 text-center">
            <span className="avg-count">{count}</span>
            <span className="card-sub-text ml015">{unit}</span>
          </div>
        </Card>
      </Col>
    );
  };

  return (
    <Row gutter={[0, 8]} justify="space-between" className="overview">
      {innerContent(
        TOTAL_KM,
        "Total Distance",
        tripSummary?.totalDistance,
        UNITS.KM
      )}

      {innerContent(
        DISTANCE_KM,
        "Distance/Charge",
        tripSummary?.distancePerCharge,
        UNITS.KM
      )}

      {innerContent(
        AVG_SPEED,
        "Avg Speed",
        tripSummary?.averageSpeed,
        UNITS.KM
      )}

      {innerContent(
        RIDE_DURATION,
        "Ride Time",
        tripSummary?.tripDuration,
        UNITS.HOURS
      )}

      {innerContent(
        STOP_DURATION,
        "Stops",
        tripSummary?.stopDuration,
        UNITS.HOURS
      )}
    </Row>
  );
};

export default Overview;
