import { Card, Col, Row, Select } from "antd";
import SCOOTER3 from "../../assets/images/red.svg";
import { useSelector } from "react-redux";
import { formatDateAndTime } from "../Common/Utils";
import { useState } from "react";

const VehicleDetails = ({ onChangeImei, imei, cardHeight }) => {
  const imeiList = useSelector((state) => state.eol.imeiList);
  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <Card
      bordered={false}
      className="pd05"
      style={{ minHeight: cardHeight ? cardHeight : "auto" }}
    >
      <Row>
        <Col span={24}>
          {imeiList && imeiList.length > 0 && (
            <Select
              size="large"
              showSearch
              value={imei || imeiList[0]}
              style={{ width: "100%" }}
              onChange={onChangeImei}
              options={imeiList.map((i) => ({
                label: i,
                value: i,
              }))}
            />
          )}
        </Col>
      </Row>
      <Row className="mt1">
        <Col span={24}>
          <div
            className="vehicle-img"
            onMouseOver={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              alt="Brand"
              src={
                vehicleDetails?.images?.length > 0
                  ? vehicleDetails?.images[0]
                  : SCOOTER3
              }
              width="100%"
              height="auto"
            />
            <Row>
              <Col span={24}>
                <Row gutter={8} className="mt05">
                  <Col className="card-bold-text fs1">
                    {vehicleDetails?.vehicleModelName}
                  </Col>
                </Row>
                <Row gutter={8} className="mt025" title="Color">
                  <Col className="card-sub-text">{vehicleDetails?.color}</Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div
            className={`${open ? "show" : "hide"} vehicle-drawer`}
            onMouseOver={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Row>
              <Col span={24}>
                <Row gutter={8}>
                  <Col className="card-bold-text fs1">
                    {vehicleDetails?.vehicleModelName}
                  </Col>
                </Row>
                <Row gutter={8} className="mt1" title="Color">
                  <Col className="title">{vehicleDetails?.color}</Col>
                </Row>
                <Row gutter={8} className="mt05">
                  <Col className="title" title="Born Date">
                    {formatDateAndTime(vehicleDetails?.birthOfVehicle)}
                  </Col>
                </Row>
                <Row gutter={8} className="mt05">
                  <Col className="title" title="Manufacture Date">
                    {formatDateAndTime(vehicleDetails?.mfrDate)}
                  </Col>
                </Row>
                <Row gutter={8} className="mt05">
                  <Col className="title" title="Weight">
                    {vehicleDetails?.weight} kg
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default VehicleDetails;
