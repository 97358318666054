import { Col, Row } from "antd";
import Filters from "./Filters";
import Panels from "./Panels";

const DriverBehaviour = () => {
  return (
    <Row>
      <Col span={24}>
        <Filters />
        <Row>
          <Col span={24}>
            <Panels />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DriverBehaviour;
