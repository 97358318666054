import { jwtResetPasswordUrl } from "../Common/endpoints";
import { NicheForgotPassword } from "niche-ui-component";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Col, Row } from "antd";
import { useOrganization } from "../../OrganizationProvider";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const organization = useOrganization();

  const handleSubmit = async (response) => {
    toast.success(response?.data?.message);
    navigate("/");
  };

  return (
    <Row style={{ height: "100vh" }} justify="center" className="grey-bg">
      <Col xs={24} sm={24} md={12} lg={10} xl={10}>
        <Row className="mt2">
          <Col span={24} className="center">
            <Link to="/">
              <img src={organization.outerLogo} alt="Logo" />
            </Link>
          </Col>
        </Row>
        <Card className="mt2" bodyStyle={{ padding: "20px" }}>
          <Row>
            <Col span={24} className="h1-header">
              Forgot Password
            </Col>
            <Col span={24} className="value mt05">
              Please enter an email to send reset password link
            </Col>
            <Col span={24} className="mt2">
              <NicheForgotPassword
                handleSubmit={handleSubmit}
                url={jwtResetPasswordUrl}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
