import { roleListApiUrl } from "../Common/endpoints";
import { Card, Col, Row } from "antd";
import { getApis } from "../Common/apis";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setRoles } from "../../reducers/authSlice";
import RegisterForm from "./Form";

const Register = () => {
  const dispatch = useDispatch();
  const authToken = localStorage.getItem("authToken");

  const getRoles = useCallback(async () => {
    await getApis(roleListApiUrl, {}, authToken)
      .then((response) => dispatch(setRoles(response.data)))
      .catch((e) => console.log(e));
  }, [dispatch, authToken]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  return (
    <Row justify="center">
      <Col xs={24} sm={24} md={12} lg={10} xl={10}>
        <Card title="Add User" bodyStyle={{ padding: "1rem 1.5rem" }}>
          <RegisterForm />
        </Card>
      </Col>
    </Row>
  );
};

export default Register;
