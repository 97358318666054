import React, { useCallback, useEffect } from "react";
import { Col, Row } from "antd";
import { getApis } from "../../../../Common/apis";
import { useDispatch, useSelector } from "react-redux";
import { vehicleTestResultApiUrl } from "../../../../Common/endpoints";
import Result from "./Panels/Result";
import History from "./Panels/History";
import {
  setConnectivityStop,
  setTestRunning,
} from "../../../../../reducers/eolSlice";
import Comment from "../Comment";

const Connectivity = ({ isModalOpen, handleCloseModal, onChangeImei, imei }) => {
  const dispatch = useDispatch();
  const eol = useSelector((state) => state.eol);
  const vehicleDetails = eol?.vehicleDetails;
  const testRunning = eol?.testRunning;
  const selectedTab = eol?.selectedTab;
  const authToken = localStorage.getItem("authToken");
  const connectivityStart = eol?.connectivityStart;
  const connectivityStop = eol?.connectivityStop;

  const resultApi = useCallback(async () => {
    const response = await getApis(
      vehicleTestResultApiUrl,
      {
        testId: vehicleDetails?.previousTests?.connectivity,
        imei: vehicleDetails?.imeiNumber,
      },
      authToken
    );
    dispatch(setConnectivityStop(response?.data));
    dispatch(setTestRunning(response?.data?.status === "RUNNING"));
  }, [
    authToken,
    dispatch,
    vehicleDetails?.imeiNumber,
    vehicleDetails?.previousTests?.connectivity,
  ]);

  useEffect(() => {
    if (
      vehicleDetails?.previousTests?.connectivity &&
      selectedTab === "connectivity"
    ) {
      resultApi();
    }
  }, [resultApi, selectedTab, vehicleDetails]);

  return (
    <Row gutter={16}>
      <Comment
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        testId={connectivityStart?.testId || connectivityStop?.testId}
        formName={selectedTab}
      />
      <Col span={24}>{testRunning === true ? <Result onChangeImei={onChangeImei} imei={imei} /> : <History onChangeImei={onChangeImei} imei={imei} />}</Col>
    </Row>
  );
};

export default Connectivity;
