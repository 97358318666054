import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import eolSlice from "./eolSlice";
import filterSlice from "./filterSlice";
import rideSlice from "./testRideSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  eol: eolSlice,
  filter: filterSlice,
  testRide: rideSlice,
});

export default rootReducer;
