import "./styles.css";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { timeRangePresets } from "../Utils";
import {
  Col,
  DatePicker,
  Popover,
  Row,
  Button,
  Divider,
  Input,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  UpOutlined,
  DownOutlined,
  ZoomOutOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import ResetIcon from "../../../assets/images/icons/time-reset.svg";

const DateTimePicker = ({
  dates,
  extraFilter,
  minusIcon,
  plusIcon,
  zoomInIcon,
  resetIcon,
  featureDate,
  disableRange,
  onChangePicker,
  notDefaultValue,
}) => {
  const [pickerDetails, setPickerDetails] = useState({
    from: dayjs().subtract(30, "m"),
    to: dayjs(),
    rangeDiff: Math.abs(dayjs().subtract(30, "m").diff(dayjs(), "h")),
    resetRange: null,
  });
  const [displayDates, setDisplayDates] = useState({
    from: notDefaultValue ? null : dayjs().subtract(30, "m"),
    to: notDefaultValue ? null : dayjs(),
  });
  const [isDatePopoverVisible, setDatePopoverVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  useEffect(() => {
    if (dates && dates.length > 1) {
      setPickerDetails((prev) => ({
        ...prev,
        from: dayjs(dates?.[0]),
        to: dayjs(dates?.[1]),
        rangeDiff: Math.abs(dayjs(dates?.[0]).diff(dayjs(dates?.[1]), "h")),
        resetRange: null,
      }));
      setDisplayDates({ from: dayjs(dates?.[0]), to: dayjs(dates?.[1]) });
    } else {
      if (!notDefaultValue) {
        onChangePicker([pickerDetails.from, pickerDetails.to]);
      }
    }

    // eslint-disable-next-line
  }, [dates]);

  const onChange = (name, value) => {
    setPickerDetails((prev) => ({
      ...prev,
      [name]: dayjs(value)
        .set("hour", prev[name].hour())
        .set("minute", prev[name].minute()),
    }));
  };

  const onChangeTime = (event) => {
    let hour = 0;
    let mins = 0;
    if (event.target.value) {
      hour = event.target.value.split(":")[0];
      mins = event.target.value.split(":")[1];
    }

    const updateTime = dayjs(pickerDetails[event.target.name])
      .set("hour", hour)
      .set("minute", mins)
      .set("second", 0);

    setPickerDetails((prev) => ({
      ...prev,
      [event.target.name]: updateTime,
    }));

    const compareValue =
      event.target.name === "from"
        ? dayjs(pickerDetails.to) < updateTime
        : updateTime < dayjs(pickerDetails.from);
    if (compareValue) {
      setErrorMessage((prev) => ({ start: `"From" can't be after "To"` }));
    } else {
      setErrorMessage({});
    }
  };

  const onFilter = (value) => {
    handlePopoverVisibleChange(false);
    if (value?.length > 1) {
      setPickerDetails((prev) => ({
        ...prev,
        from: value[0],
        to: value[1],
        rangeDiff: Math.abs(value[0].diff(value[1], "h")),
        resetRange: value,
      }));
    }
    setErrorMessage({});
    onChangePicker(value);
    setDisplayDates((prev) => ({ ...prev, from: value[0], to: value[1] }));
  };

  const onRangeChange = (type) => {
    let range = 1;
    let dayType = "m";
    if (pickerDetails.rangeDiff <= 24) {
      range = 30;
      dayType = "m";
    } else if (pickerDetails.rangeDiff <= 48) {
      range = 1;
      dayType = "h";
    } else {
      range = 1;
      dayType = "d";
    }
    range = type === "prev" ? Math.abs(range) * -1 : Math.abs(range);
    onFilter([
      dayjs(pickerDetails.from).add(range, dayType),
      dayjs(pickerDetails.to).add(range, dayType),
    ]);
  };

  const onSubmitRange = () => {
    if (Object.keys(errorMessage)?.length === 0) {
      handlePopoverVisibleChange(false);
      setPickerDetails((prev) => ({
        ...prev,
        rangeDiff: [Math.abs(prev.from.diff(prev.to, "h"))],
        resetRange: null,
      }));
      onChangePicker([pickerDetails.from, pickerDetails.to]);
      setDisplayDates((prev) => ({
        ...prev,
        from: pickerDetails.from,
        to: pickerDetails.to,
      }));
    }
  };

  const onZoomRange = () => {
    const range = pickerDetails.rangeDiff <= 24 ? 30 : 1;
    const dayType =
      pickerDetails.rangeDiff <= 24
        ? "m"
        : pickerDetails.rangeDiff <= 48
        ? "h"
        : "d";
    const startDate = dayjs(pickerDetails.from).add(range, dayType);
    const endDate = dayjs(pickerDetails.to).subtract(range, dayType);
    const resetRange = pickerDetails.resetRange
      ? pickerDetails.resetRange
      : [pickerDetails.from, pickerDetails.to];
    if (pickerDetails.rangeDiff > 1) {
      setPickerDetails((prev) => ({
        ...prev,
        from: startDate,
        to: endDate,
        rangeDiff: Math.abs(startDate.diff(endDate, "h")),
        resetRange,
      }));
    }
    onChangePicker([startDate, endDate]);
    setDisplayDates((prev) => ({
      ...prev,
      from: startDate,
      to: endDate,
    }));
  };

  const onResetRange = () => {
    if (pickerDetails.resetRange) {
      onFilter(pickerDetails.resetRange);
      setErrorMessage({});
    }
  };

  const disabledFromDate = (current) => {
    if (featureDate) {
      return current && current.valueOf() > dayjs().endOf("day");
    }

    return null;
  };

  const disabledToDate = (current) => {
    if (disableRange) {
      return (
        current &&
        current.valueOf() > dayjs(pickerDetails.from).add(disableRange, "h")
      );
    }

    if (featureDate) {
      return current && current.valueOf() > dayjs().endOf("day");
    }

    return null;
  };

  const DatePickerChange = (value, type) => {
    onChange(type, value, pickerDetails);
    const compareValue =
      type === "from"
        ? dayjs(pickerDetails.to) < value
        : value < dayjs(pickerDetails.from);
    if (compareValue) {
      setErrorMessage((prev) => ({
        ...prev,
        start: `"From" can't be after "To"`,
      }));
    } else {
      setErrorMessage({});
    }
  };

  const content = (
    <Row gutter={[16, 10]} className="ev-date-picker-popup">
      <Col xs={24} sm={extraFilter ? 15 : 24}>
        <Row className="mt1">From</Row>
        <Row gutter={10} className="mt05">
          <Col xs={14} sm={15}>
            <DatePicker
              allowClear={false}
              onChange={(value, dateString) => DatePickerChange(value, "from")}
              value={pickerDetails.from}
              disabledDate={disabledFromDate}
              format="YYYY-MM-DD"
              size="middle"
              popupClassName="ev-date-picker"
            />
          </Col>
          <Col xs={10} sm={9}>
            <Input
              placeholder="00:00"
              size="middle"
              type="time"
              name="from"
              onChange={onChangeTime}
              value={dayjs(pickerDetails.from).format("HH:mm")}
            />
          </Col>
          {errorMessage?.start ? (
            <Col span={24}>
              <span className="error">{errorMessage?.start}</span>
            </Col>
          ) : null}
        </Row>
        <Row className="mt1">To</Row>
        <Row gutter={10} className="mt05">
          <Col xs={14} sm={15}>
            <DatePicker
              allowClear={false}
              onChange={(value, dateString) => DatePickerChange(value, "to")}
              value={pickerDetails.to}
              disabledDate={disabledToDate}
              size="middle"
              format="YYYY-MM-DD"
              popupClassName="ev-date-picker"
            />
          </Col>
          <Col xs={10} sm={9}>
            <Input
              placeholder="00:00"
              size="middle"
              type="time"
              name="to"
              onChange={onChangeTime}
              value={dayjs(pickerDetails.to).format("HH:mm")}
            />
          </Col>
          {errorMessage?.to ? (
            <Col span={24}>
              <span className="error">{errorMessage?.to}</span>
            </Col>
          ) : null}
        </Row>
        <Row justify={"end"} className="mt05">
          <Button
            className="submit-btn mt1"
            htmlType="button"
            onClick={onSubmitRange}
          >
            Apply
          </Button>
        </Row>
      </Col>
      {extraFilter && (
        <>
          <Col xs={0} sm={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col xs={24} sm={8}>
            <Row gutter={[16, 10]}>
              {timeRangePresets.map((i) => (
                <Col
                  xs={12}
                  sm={24}
                  className={i.label + " cursor-pointer"}
                  onClick={() => onFilter(i.value)}
                >
                  {i.label}
                </Col>
              ))}
            </Row>
          </Col>
        </>
      )}
    </Row>
  );

  const handlePopoverVisibleChange = (value) => {
    setDatePopoverVisible(value);
  };

  const rangePopoverClose = () => {
    setDatePopoverVisible(false);
  };

  const title = (
    <Row justify={"space-between"}>
      <Col>Absolute time range</Col>
      <Col>
        <CloseOutlined onClick={rangePopoverClose} />
      </Col>
    </Row>
  );

  return (
    <Row gutter={10} className="ev-date-range-lable-box large mlr0">
      {minusIcon ? (
        <Col xs={0} sm={2} className="filter-icon-btn right-side-border">
          <Tooltip
            title={
              "Previous " +
              (pickerDetails.rangeDiff <= 24
                ? "30 mins"
                : pickerDetails.rangeDiff <= 48
                ? "1 hour"
                : "1 day")
            }
          >
            <MinusOutlined
              color="#d9d9d9"
              onClick={() => onRangeChange("prev")}
            />
          </Tooltip>
        </Col>
      ) : null}
      <Col flex="auto">
        <Popover
          placement="bottomLeft"
          arrow={false}
          title={title}
          open={isDatePopoverVisible}
          onOpenChange={handlePopoverVisibleChange}
          content={content}
          trigger="click"
          overlayClassName="ev-date-range-picker-popup"
        >
          <Row gutter={10} className="date-range-lable" wrap={false}>
            <Col className="date-text" flex="auto">
              {displayDates?.from && displayDates?.to ? (
                <span>
                  {dayjs(displayDates.from).format("YYYY-MM-DD HH:mm")} to{" "}
                  {dayjs(displayDates.to).format("YYYY-MM-DD HH:mm")}
                </span>
              ) : (
                <span />
              )}
            </Col>
            <Col className="arrow" flex="24px">
              {isDatePopoverVisible ? <UpOutlined /> : <DownOutlined />}
            </Col>
          </Row>
        </Popover>
      </Col>
      {plusIcon ? (
        <Col xs={0} sm={2} className="filter-icon-btn left-side-border">
          <Tooltip
            title={
              "Next " +
              (pickerDetails.rangeDiff <= 24
                ? "30 mins"
                : pickerDetails.rangeDiff <= 48
                ? "1 hour"
                : "1 day")
            }
          >
            <PlusOutlined onClick={() => onRangeChange("next")} />
          </Tooltip>
        </Col>
      ) : null}
      {zoomInIcon ? (
        <Col xs={0} sm={2} className="filter-icon-btn left-side-border">
          <Tooltip title={"Zoom In"}>
            <ZoomOutOutlined onClick={onZoomRange} />
          </Tooltip>
        </Col>
      ) : null}
      {resetIcon ? (
        <Col xs={0} sm={2} className="filter-icon-btn left-side-border">
          <Tooltip title={"Reset"}>
            <img
              src={ResetIcon}
              height={"15px"}
              alt="#"
              style={{ verticalAlign: "middle" }}
              className="cursor-pointer"
              onClick={onResetRange}
            />
          </Tooltip>
        </Col>
      ) : null}
    </Row>
  );
};

export default DateTimePicker;
