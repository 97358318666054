const BASE_URL = process.env.REACT_APP_GRAFANA_PROXY_BASE_URL;
const MOTOR_STATUS =
  "d-solo/cf7a11f5-3451-4ced-91c5-4659b72e163c/motor-status";
const DRIVER_BEHAVIOUR =
  "d-solo/df7bae09-a1aa-415b-b17f-033da73283ad/driver-behaviour";

export const MOTOR_STATUS_TEMP_LINE_CHART = (orgId, fleet, imei, motorField) =>
  `${BASE_URL}/${MOTOR_STATUS}?orgId=${orgId}&from=1685559491000&to=1688078017000&var-fleet=${fleet}&var-imei=${imei}&var-bucket_interval=1h&var-motorfield=${motorField}&panelId=2`;

export const MOTOR_STATUS_TEMP_BAR_CHART = (orgId, fleet, imei, motorField) =>
  `${BASE_URL}/${MOTOR_STATUS}?orgId=${orgId}&from=1685559491000&to=1688078017000&var-fleet=${fleet}&var-imei=${imei}&var-bucket_interval=1h&var-motorfield=${motorField}&panelId=3`;

export const MOTOR_STATUS_TEMP_BOX_CHART = (orgId, fleet, imei, motorField) =>
  `${BASE_URL}/${MOTOR_STATUS}?orgId=${orgId}&from=1685559491000&to=1688078017000&var-fleet=${fleet}&var-imei=${imei}&var-bucket_interval=1h&var-motorfield=${motorField}&panelId=6`;

export const MOTOR_STATUS_TEMP_CANDLESTICK_CHART = (
  orgId,
  fleet,
  imei,
  motorField
) =>
  `${BASE_URL}/${MOTOR_STATUS}?orgId=${orgId}&from=1685559491000&to=1688078017000&var-fleet=${fleet}&var-imei=${imei}&var-bucket_interval=1h&var-motorfield=${motorField}&panelId=7`;

export const DRIVER_BEHAVIOUR_SCATTER_CHART = (orgId, fleet, imei) =>
  `${BASE_URL}/${DRIVER_BEHAVIOUR}?orgId=${orgId}&from=1685559491000&to=1688078017000&var-fleet=${fleet}&var-imei=${imei}&panelId=1`;

export const DRIVER_BEHAVIOUR_X_BOX_CHART = (orgId, fleet, imei) =>
  `${BASE_URL}/${DRIVER_BEHAVIOUR}?orgId=${orgId}&from=1685559491000&to=1688078017000&var-fleet=${fleet}&var-imei=${imei}&panelId=2`;

export const DRIVER_BEHAVIOUR_Y_BOX_CHART = (orgId, fleet, imei) =>
  `${BASE_URL}/${DRIVER_BEHAVIOUR}?orgId=${orgId}&from=1685559491000&to=1688078017000&var-fleet=${fleet}&var-imei=${imei}&panelId=3`;

export const DRIVER_BEHAVIOUR_Z_BOX_CHART = (orgId, fleet, imei) =>
  `${BASE_URL}/${DRIVER_BEHAVIOUR}?orgId=${orgId}&from=1685559491000&to=1688078017000&var-fleet=${fleet}&var-imei=${imei}&panelId=4`;
