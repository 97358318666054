import React, { createContext, useContext, useEffect, useState } from "react";
import { getApis } from "./components/Common/apis";
import { organisationInfoUrl } from "./components/Common/endpoints";
const OrganizationContext = createContext();

export default function OrganizationProvider({ children }) {
  const [organization, setOrganization] = useState({});

  const getOrgInfo = async () => {
    await getApis(organisationInfoUrl)
      .then((response) => {
        setOrganization(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrgInfo();
  }, []);

  return (
    <OrganizationContext.Provider value={organization}>
      {children}
    </OrganizationContext.Provider>
  );
}

export function useOrganization() {
  return useContext(OrganizationContext);
}
