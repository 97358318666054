import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ConnectivityStartTest = ({ stopTest, startTest }) => {
  const eol = useSelector((state) => state.eol);
  const testRunning = eol?.testRunning;
  const [countdown, setCountdown] = useState(60);
  const dispatch = useDispatch();

  useEffect(() => {
    let interval;
    if (testRunning) {
      if (countdown > 0) {
        interval = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
      } else {
        clearInterval(interval);
        stopTest();
        setCountdown(60);
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [countdown, dispatch, stopTest, testRunning]);

  return (
    <>
      <span className="mr1 fs1">{countdown}s</span>
      {testRunning ? (
        <a href="#123" className="stop-disabled" disabled>
          Testing...
        </a>
      ) : (
        <a href="#123" className="start" onClick={startTest}>
          Start Test
        </a>
      )}
    </>
  );
};

export default ConnectivityStartTest;
