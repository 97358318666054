import {
  jwtChangePasswordUrl,
  changePasswordSchemaUrl,
} from "../Common/endpoints";
import { NicheFormEngine } from "niche-ui-component";
import { jwtChangePassword } from "niche-user-fe-service";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getApis } from "../Common/apis";
import { uiSchema } from "./uiSchema";
import { Card, Col, Row } from "antd";
import { useOrganization } from "../../OrganizationProvider";

const ChangePassword = () => {
  const [schema, setSchema] = useState({});
  const [error, setError] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const organization = useOrganization();

  const getSchema = async () => {
    await getApis(changePasswordSchemaUrl)
      .then((response) => {
        setSchema({
          type: response?.data?.type,
          properties: response?.data?.properties,
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!token) {
      navigate("/");
      toast.warning("Invalid URL", {
        theme: "colored",
        hideProgressBar: true,
        draggable: false,
      });
    }
    getSchema();
  }, [navigate, token]);

  const callChangePasswordService = async (existingFormData) => {
    let formData = new FormData();
    Object.keys(existingFormData).forEach((key) => {
      formData.set(key, existingFormData[key]);
    });
    formData.set("token", token);
    await jwtChangePassword(jwtChangePasswordUrl, formData)
      .then((res) => {
        toast.success(res?.data?.message);
        navigate("/login");
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (response) => {
    const existingFormData = response?.formData;
    if (!existingFormData?.newPassword && !existingFormData?.oldPassword) {
      setError("Please enter required fields");
    } else if (!existingFormData?.newPassword) {
      setError("New Password can't be blank");
    } else if (!existingFormData?.oldPassword) {
      setError("Old Password can't be blank");
    } else {
      setError("");
      callChangePasswordService(existingFormData);
    }
  };

  return (
    <Row style={{ height: "100vh" }} justify="center" className="grey-bg">
      <Col xs={24} sm={24} md={12} lg={10} xl={10}>
        <Row className="mt2">
          <Col span={24} className="center">
            <Link to="/">
              <img src={organization.outerLogo} alt="Logo" />
            </Link>
          </Col>
        </Row>
        <Card className="mt2" bodyStyle={{ padding: "20px" }}>
          <Row>
            <Col span={24} className="h1-header">
              Reset Password
            </Col>
            <Col span={24} className="value mt05">
              Please enter valid password to reset
            </Col>
            <Col span={24} className="mt1 error">
              {error}
            </Col>
            <Col span={24} className="mt1">
              {schema && (
                <NicheFormEngine
                  handleSubmit={handleSubmit}
                  schema={schema}
                  uiSchema={uiSchema}
                />
              )}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default ChangePassword;
