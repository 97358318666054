import { jwtConfirmUrl } from "../Common/endpoints";
import { jwtConfirmEmail } from "niche-user-fe-service";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const RegistrationConfirm = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const navigate = useNavigate();

  const callConfirmService = useCallback(async () => {
    await jwtConfirmEmail(jwtConfirmUrl, token)
      .then((res) => {
        toast.success(res?.data?.message, {
          theme: "colored",
          hideProgressBar: true,
          draggable: false,
        });
        navigate("/login");
      })
      .catch((err) => console.log(err));
  }, [navigate, token]);

  useEffect(() => {
    if (!token) {
      navigate("/");
      toast.warning("Invalid URL", {
        theme: "colored",
        hideProgressBar: true,
        draggable: false,
      });
    }
    callConfirmService();
  }, [callConfirmService, navigate, token]);
};

export default RegistrationConfirm;
