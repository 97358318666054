import { Card, Col, Row } from "antd";

const Profile = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  return (
    <Row justify="center" className="mt1">
      <Col span={12}>
        <Card bordered={false} title="Profile" bodyStyle={{ padding: "20px" }}>
          <Row gutter={16}>
            <Col className="bold">Name: </Col>
            <Col>
              {userDetails?.firstName} {userDetails?.lastName}
            </Col>
          </Row>
          <Row gutter={16} className="mt1">
            <Col className="bold">Email: </Col>
            <Col>{userDetails?.email}</Col>
          </Row>
          <Row gutter={16} className="mt1">
            <Col className="bold">Organisation: </Col>
            <Col>
              {userDetails?.currentOrganisation?.name} -{" "}
              {userDetails?.currentOrganisation?.type}
            </Col>
          </Row>
          <Row gutter={16} className="mt1">
            <Col className="bold">Role: </Col>
            <Col>{userDetails?.currentRole}</Col>
          </Row>
          <Row gutter={16} className="mt1">
            <Col className="bold">Phone: </Col>
            <Col>{userDetails?.phoneNumber}</Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Profile;
