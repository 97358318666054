import TextArea from "antd/es/input/TextArea";
import { Button, Modal, Form, Radio } from "antd";
import {
  vehicleAddCommentsApiUrl,
  vehicleTestListUrl,
} from "../../../Common/endpoints";
import { getApis, postApis } from "../../../Common/apis";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  setConnectivityHistoryData,
  setDynoHistoryData,
} from "../../../../reducers/eolSlice";

const Comment = ({ isModalOpen, handleCloseModal, testId, formName }) => {
  const eol = useSelector((state) => state.eol);
  const vehicleDetails = eol?.vehicleDetails;
  const selectedTab = eol?.selectedTab;
  const authToken = localStorage.getItem("authToken");
  const dispatch = useDispatch();

  const getConHistoryData = async () => {
    await getApis(
      vehicleTestListUrl,
      {
        imei: vehicleDetails.imeiNumber,
        type: "CONNECTIVITY",
      },
      authToken
    )
      .then((res) => dispatch(setConnectivityHistoryData(res?.data)))
      .catch((e) => console.log(e));
  };

  const getDynoHistoryData = async () => {
    await getApis(
      vehicleTestListUrl,
      {
        imei: vehicleDetails.imeiNumber,
        type: "DYNO",
      },
      authToken
    )
      .then((res) => dispatch(setDynoHistoryData(res?.data)))
      .catch((e) => console.log(e));
  };

  let initialValues = {
    key: "Is the vehicle in good condition without any issues so far?",
    testId: testId,
    commentType: "TEXT",
    value: "yes",
    comment: "",
  };

  const addComment = async (values) => {
    const body = {
      ...initialValues,
      value: values?.value || "yes",
      comment: values?.comment,
    };

    await postApis(vehicleAddCommentsApiUrl, body, authToken)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Commented successfully!");
          handleCloseModal();
          if (selectedTab === "connectivity") {
            getConHistoryData();
          } else if (selectedTab === "dyno") {
            getDynoHistoryData();
          }
        }
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      });
  };

  const onFinish = async (values) => {
    addComment(values);
  };

  const options = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  return (
    <Modal
      title="Vehicle Status"
      open={isModalOpen}
      closable={false}
      footer={[]}
    >
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        name={formName}
      >
        Is the vehicle in good condition without any issues so far?
        <Form.Item key="radio" name="value">
          <Radio.Group name="value" className="mtb1">
            {options.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          key="comment"
          name="comment"
          rules={[
            {
              required: true,
              message: "Can't be blank",
            },
          ]}
        >
          <TextArea placeholder="Comments" rows="3" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" className="comment-btn right">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Comment;
