import { useSelector } from "react-redux";
import { Empty, Progress, Table } from "antd";
import { UNITS } from "../../../../../Common/Constants";

const ModeTable = () => {
  const eol = useSelector((state) => state.eol);
  const vehicleDetails = eol?.vehicleDetails;
  const response =
    Object.keys(eol?.dynoStop).length !== 0 ? eol?.dynoStop : eol?.dynoResult;

  const returnSpeedWithIcon = (modeValue, bool) => {
    if (modeValue || modeValue === 0) {
      return {
        speed: modeValue,
        checked: bool,
      };
    }
  };
  const modeResult = (item) => {
    if (item.mode === "ECO") {
      return returnSpeedWithIcon(
        response?.data?.maxSpeed?.eco,
        response?.data?.threshold?.eco_test_passed
      );
    } else if (item.mode === "CITY") {
      return returnSpeedWithIcon(
        response?.data?.maxSpeed?.city,
        response?.data?.threshold?.city_test_passed
      );
    } else if (item.mode === "POWER") {
      return returnSpeedWithIcon(
        response?.data?.maxSpeed?.power,
        response?.data?.threshold?.power_test_passed
      );
    } else if (item.mode === "REVERSE") {
      return returnSpeedWithIcon(
        response?.data?.maxSpeed?.reverse,
        response?.data?.threshold?.reverse_test_passed
      );
    }
  };

  const connectionIcon = (status) => {
    if (status === true) {
      return (
        <Progress
          type="circle"
          size={25}
          percent={100}
          strokeColor={{
            "0%": "#108ee9",
            "100%": "#87d068",
          }}
        />
      );
    } else if (status === false) {
      return (
        <Progress type="circle" size={25} percent={100} status="exception" />
      );
    } else {
      return "";
    }
  };

  const columns = [
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
    },
    {
      title: "Target",
      dataIndex: "testDate",
      key: "testDate",
      render: (_, record) => `${record.max} ${UNITS.KM_PER_HR}`,
    },
    {
      title: "Speed",
      dataIndex: "speed",
      key: "speed",
      render: (_, record) =>
        modeResult(record)?.speed.toFixed(2) &&
        `${modeResult(record)?.speed.toFixed(2)} ${UNITS.KM_PER_HR}`,
    },
    {
      title: "Result",
      dataIndex: "result",
      key: "result",
      render: (_, record) => connectionIcon(modeResult(record)?.checked),
    },
  ];

  return vehicleDetails?.driveModeSpeed?.length > 0 ? (
    <Table
      className="dyno-mode-table"
      columns={columns}
      rowKey={(record) => record.mode}
      dataSource={vehicleDetails?.driveModeSpeed}
      pagination={false}
      scroll={{ x: "100%" }}
    />
  ) : (
    <div className="pd3025">
      <Empty />
    </div>
  );
};

export default ModeTable;
