import { Col, Row } from "antd";
import Filters from "./Filters";
import Panels from "./Panels";

const MotorStatus = () => {
  return (
    <Row>
      <Col span={24}>
        <Filters />
        <Panels />
      </Col>
    </Row>
  );
};

export default MotorStatus;
