import { Card, Col, Row } from "antd";
import TOTAL_KM from "../../../assets/images/icons/totalkm.svg";
import DISTANCE_KM from "../../../assets/images/icons/distance.svg";
import AVG_SPEED from "../../../assets/images/icons/avg-speed.svg";
import RIDE_DURATION from "../../../assets/images/icons/ride-duration.svg";
import STOP_DURATION from "../../../assets/images/icons/stop-duration.svg";
import DISCHARGE from "../../../assets/images/icons/discharge.svg";
import { UNITS } from "../../Common/Constants";

const CumulativeOverview = ({ tripSummary, summaryList }) => {
  
  const innerContent = (imgPath, title, count, unit) => {
    return (
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={summaryList.length === 2 ? 8 : 4}
        xl={summaryList.length === 2 ? 8 : 4}
        xxl={summaryList.length === 2 ? 8 : 4}
      >
        <Card
          title={
            <div className="overview-header">
              <img src={imgPath} alt="km" className="icon-size mr05" />
              {title}
            </div>
          }
        >
          <div className="mtb05 text-center">
            <span className="avg-count">{count}</span>
            <span className="card-sub-text ml015">{unit}</span>
          </div>
        </Card>
      </Col>
    );
  };

  return (
    <Row gutter={[16, 16]} justify="space-between" className="overview mt1">
      {innerContent(
        TOTAL_KM,
        "Total Distance",
        tripSummary?.totalDistance?.toFixed(2),
        UNITS.KM
      )}

      {innerContent(
        DISTANCE_KM,
        "Distance/Charge",
        tripSummary?.distancePerCharge?.toFixed(2),
        UNITS.KM
      )}

      {innerContent(
        AVG_SPEED,
        "Avg Speed",
        tripSummary?.averageSpeed?.toFixed(2),
        UNITS.KM
      )}

      {innerContent(
        RIDE_DURATION,
        "Total Ride Duration",
        tripSummary?.rideDuration?.toFixed(2),
        UNITS.HOURS
      )}

      {innerContent(
        STOP_DURATION,
        "Total Stops Duration",
        tripSummary?.stopDuration?.toFixed(2),
        UNITS.HOURS
      )}

      {innerContent(
        DISCHARGE,
        "Total Discharge",
        tripSummary?.totalDischarge,
        UNITS.HOURS
      )}
    </Row>
  );
};

export default CumulativeOverview;
