import React, { useCallback, useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Dropdown,
  Space,
  Breadcrumb,
  Drawer,
  Divider,
} from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { CaretDownFilled, MenuOutlined } from "@ant-design/icons";
import DASHBOARD from "../../assets/images/icons/dashboard.svg";
import AVATAR from "../../assets/images/icons/avatar.svg";
import MOBILE_AVATAR from "../../assets/images/icons/mob-avatar.svg";
import "./style.css";
import { Footer } from "antd/es/layout/layout";
import { dashboardsList, footerText, userPages } from "../Common/Utils";
import { BREADCRUMBS } from "../Common/Constants";
import { useOrganization } from "../../OrganizationProvider";
import jwtDecode from "jwt-decode";

const { Header, Content } = Layout;

const AuthLayout = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const organization = useOrganization();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const currentRole = userDetails?.currentRole;
  const currentOrganisation = userDetails?.currentOrganisation;
  const urlElements = location.pathname.split("/");
  let lastValue = urlElements[urlElements.length - 1];
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const userLinks = userPages(JSON.parse(localStorage.getItem("userDetails")));
  const decodedJwt = localStorage.getItem("authToken")
    ? jwtDecode(localStorage.getItem("authToken"))
    : "";

  const sessionTimeout = useCallback(() => {
    const isTokenExpired = Math.floor(Date.now() / 1000) > decodedJwt?.exp;
    if (isTokenExpired) {
      localStorage.clear();
      window.location.reload();
    }
  }, [decodedJwt?.exp]);

  useEffect(() => {
    sessionTimeout();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sessionTimeout]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const camelCaseToWords = (camelCaseString) => {
    return camelCaseString
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const onClickItem = (e) => {
    navigate(`/${e.key}`);
  };

  const onDropdownClick = (e) => {
    navigate(`/${e.key}`);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header className="header">
        <a href="/">
          <img
            src={`${organization.s3BucketBaseUrl}/InnerLogo.svg`}
            className="flex"
            alt="Logo"
          />
        </a>

        {isMobile ? (
          <>
            <MenuOutlined onClick={toggleDrawer} className="mob-menu" />

            <Drawer
              title={
                <div className="flex">
                  <img src={MOBILE_AVATAR} alt="avatar" className="mr1" />
                  {`${currentOrganisation?.name} - ${currentRole}`}
                </div>
              }
              placement="right"
              closable={false}
              onClose={toggleDrawer}
              open={isDrawerOpen}
            >
              {dashboardsList().map((item) => (
                <div key={item.key} className="drawer-pd">
                  <a href={`/${item.key}`}>
                    <span>{item.label}</span>
                  </a>
                </div>
              ))}

              <Divider />
              {userLinks.map(
                (item) =>
                  item.key && (
                    <div key={item.key} className="drawer-pd">
                      <a href={`/${item.key}`}>
                        <span>{item.label}</span>
                      </a>
                    </div>
                  )
              )}
            </Drawer>
          </>
        ) : (
          <>
            <Menu
              onClick={onClickItem}
              mode="horizontal"
              className="ml-auto"
              items={[]}
            />

            <Dropdown
              menu={{
                onClick: onDropdownClick,
                items: dashboardsList(),
              }}
            >
              <Space className="mr1075">
                <img src={DASHBOARD} alt="Dashboards" className="icon-margin" />
                {BREADCRUMBS.DASHBOARDS}
                <CaretDownFilled />
              </Space>
            </Dropdown>

            <Dropdown
              menu={{
                onClick: onDropdownClick,
                items: userLinks,
              }}
            >
              <Space>
                <img src={AVATAR} alt="avatar" className="icon-margin" />
                {`${currentOrganisation?.name} - ${currentRole}`}
                <CaretDownFilled />
              </Space>
            </Dropdown>
          </>
        )}
      </Header>
      <div className="breadcrumb-layout">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: BREADCRUMBS.DASHBOARDS,
            },
            {
              title: camelCaseToWords(lastValue),
            },
          ]}
        />
      </div>
      <Layout className="layout">
        <Content className="content-layout">{props.children}</Content>
      </Layout>
      <Footer className="footer">{footerText}</Footer>
    </Layout>
  );
};

export default AuthLayout;
