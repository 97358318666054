import { Carousel, Col, Row } from "antd";
import VEHICLE from "../../../assets/images/dealer/model.svg";
import TOP_RANGE from "../../../assets/images/icons/range.svg";
import ACCELERATION from "../../../assets/images/icons/acceleration.svg";
import CHARGING from "../../../assets/images/icons/charging.svg";
import TOP_SPEED from "../../../assets/images/icons/top-speed.svg";

const Overview = () => {
  return (
    <Carousel autoplay>
      <Row gutter={[16, 16]}>
        <div className="carousal-style">
          <Col xs={24} sm={24} md={24} lg={8} xl={8} className="flex-center">
            <img src={VEHICLE} alt="Scooter" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <div className="dealer-card">
              <div className="dealer-card-head">PERFORMANCE</div>
              <div className="dealer-card-body">
                <Row gutter={[16, 16]} justify="space-evenly" className="pd1">
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      <div className="mt05 flex-center">
                        <img src={TOP_RANGE} alt="Top Range" />
                      </div>
                      <div className="mt025 f12">Top Range</div>
                      <div className="head24-bold mt075">180</div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      {" "}
                      <div className="mt05 flex-center">
                        <img src={TOP_SPEED} alt="Top Speed" />
                      </div>
                      <div className="mt025 f12">Top Speed</div>
                      <div className="head24-bold mt075">120</div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      {" "}
                      <div className="mt05 flex-center">
                        <img src={CHARGING} alt="Charging" />
                      </div>
                      <div className="mt025 f12">Charge Time</div>
                      <div className="flex-baseline mt075">
                        <div className="head24-bold">3 </div>
                        <div className="f14 ml015 grey-text">h</div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      {" "}
                      <div className="mt05 flex-center">
                        <img src={ACCELERATION} alt="Top Range" />
                      </div>
                      <div className="mt025 f12">Acceleration</div>
                      <div className="head24-bold mt075">50</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </div>
      </Row>
      <Row gutter={[16, 16]}>
        <div className="carousal-style">
          <Col xs={24} sm={24} md={24} lg={8} xl={8} className="flex-center">
            <img src={VEHICLE} alt="Scooter" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <div className="dealer-card">
              <div className="dealer-card-head">PERFORMANCE</div>
              <div className="dealer-card-body">
                <Row gutter={[16, 16]} justify="space-evenly" className="pd1">
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      <div className="mt05 flex-center">
                        <img src={TOP_RANGE} alt="Top Range" />
                      </div>
                      <div className="mt025 f12">Top Range</div>
                      <div className="head24-bold mt075">180</div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      {" "}
                      <div className="mt05 flex-center">
                        <img src={TOP_SPEED} alt="Top Speed" />
                      </div>
                      <div className="mt025 f12">Top Speed</div>
                      <div className="head24-bold mt075">120</div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      <div className="mt05 flex-center">
                        <img src={CHARGING} alt="Charging" />
                      </div>
                      <div className="mt025 f12">Charge Time</div>
                      <div className="flex-baseline mt075">
                        <div className="head24-bold">3 </div>
                        <div className="f14 ml015 grey-text">h</div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      {" "}
                      <div className="mt05 flex-center">
                        <img src={ACCELERATION} alt="Top Range" />
                      </div>
                      <div className="mt025 f12">Acceleration</div>
                      <div className="head24-bold mt075">50</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </div>
      </Row>
      <Row gutter={[16, 16]}>
        <div className="carousal-style">
          <Col xs={24} sm={24} md={24} lg={8} xl={8} className="flex-center">
            <img src={VEHICLE} alt="Scooter" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <div className="dealer-card">
              <div className="dealer-card-head">PERFORMANCE</div>
              <div className="dealer-card-body">
                <Row gutter={[16, 16]} justify="space-evenly" className="pd1">
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      <div className="mt05 flex-center">
                        <img src={TOP_RANGE} alt="Top Range" />
                      </div>
                      <div className="mt025 f12">Top Range</div>
                      <div className="head24-bold mt075">180</div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      {" "}
                      <div className="mt05 flex-center">
                        <img src={TOP_SPEED} alt="Top Speed" />
                      </div>
                      <div className="mt025 f12">Top Speed</div>
                      <div className="head24-bold mt075">120</div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      {" "}
                      <div className="mt05 flex-center">
                        <img src={CHARGING} alt="Charging" />
                      </div>
                      <div className="mt025 f12">Charge Time</div>
                      <div className="flex-baseline mt075">
                        <div className="head24-bold">3 </div>
                        <div className="f14 ml015 grey-text">h</div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      {" "}
                      <div className="mt05 flex-center">
                        <img src={ACCELERATION} alt="Top Range" />
                      </div>
                      <div className="mt025 f12">Acceleration</div>
                      <div className="head24-bold mt075">50</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </div>
      </Row>
      <Row gutter={[16, 16]}>
        <div className="carousal-style">
          <Col xs={24} sm={24} md={24} lg={8} xl={8} className="flex-center">
            <img src={VEHICLE} alt="Scooter" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <div className="dealer-card">
              <div className="dealer-card-head">PERFORMANCE</div>
              <div className="dealer-card-body">
                <Row gutter={[16, 16]} justify="space-evenly" className="pd1">
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      <div className="mt05 flex-center">
                        <img src={TOP_RANGE} alt="Top Range" />
                      </div>
                      <div className="mt025 f12">Top Range</div>
                      <div className="head24-bold mt075">180</div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      {" "}
                      <div className="mt05 flex-center">
                        <img src={TOP_SPEED} alt="Top Speed" />
                      </div>
                      <div className="mt025 f12">Top Speed</div>
                      <div className="head24-bold mt075">120</div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      {" "}
                      <div className="mt05 flex-center">
                        <img src={CHARGING} alt="Charging" />
                      </div>
                      <div className="mt025 f12">Charge Time</div>
                      <div className="flex-baseline mt075">
                        <div className="head24-bold">3 </div>
                        <div className="f14 ml015 grey-text">h</div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <div className="count-box text-center">
                      {" "}
                      <div className="mt05 flex-center">
                        <img src={ACCELERATION} alt="Top Range" />
                      </div>
                      <div className="mt025 f12">Acceleration</div>
                      <div className="head24-bold mt075">50</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </div>
      </Row>
    </Carousel>
  );
};

export default Overview;
