export const uiSchema = {
  "ui:submitButtonOptions": {
    submitText: "Reset",
  },
  newPassword: {
    "ui:widget": "password",
    "ui:options": {
      label: false,
      placeholder: "New Password *",
    },
  },
  oldPassword: {
    "ui:widget": "password",
    "ui:options": {
      label: false,
      placeholder: "Confirm Password *",
    },
  },
  token: {
    "ui:widget": "hidden",
  },
};
