import { Tabs } from "antd";
import { useState } from "react";
import Connectivity from "./Connectivity";
import Dyno from "./Dyno";

const EolStatistics = () => {
  const [activeTab, setActiveTab] = useState("heartBeat");

  const onChangeTab = (key) => {
    setActiveTab(key);
  };

  const items = [
    {
      key: "heartBeat",
      label: `Heart Beat`,
      children: <Connectivity />,
    },
    {
      key: "dyno",
      label: `Dyno`,
      children: <Dyno />,
    },
  ];

  return (
    <Tabs defaultActiveKey={activeTab} items={items} onChange={onChangeTab} />
  );
};

export default EolStatistics;
