import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Tabs } from "antd";
import { getApis, postApis } from "../../../Common/apis";
import {
  vehicleDetailsApiUrl,
  imeiListApiUrl,
  vehicleTestStopApiUrl,
  vehicleTestStartApiUrl,
} from "../../../Common/endpoints";
import { useDispatch, useSelector } from "react-redux";
import {
  setVehicleDetails,
  setImeiList,
  setConnectivityStop,
  setConnectivityStart,
  setTestRunning,
  setSelectedTab,
  setDynoStart,
  setDynoStop,
} from "../../../../reducers/eolSlice";
import ConnectivityStartTest from "./Connectivity/Panels/StartTest";
import Connectivity from "./Connectivity";
import Dyno from "./Dyno";
import DynoStartTest from "./Dyno/Panels/StartTest";
import { toast } from "react-toastify";

const EolTests = () => {
  const dispatch = useDispatch();
  const eol = useSelector((state) => state.eol);
  const vehicleDetails = eol?.vehicleDetails;
  const selectedTab = eol?.selectedTab;
  const dynoResult = eol?.dynoResult;
  const [imei, setImei] = useState("");
  const connectivityStart = eol?.connectivityStart;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const authToken = localStorage.getItem("authToken");

  const showCommentModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getImeiList = useCallback(async () => {
    await getApis(`${imeiListApiUrl}?page=0&size=15&sort=asc`, {}, authToken)
      .then((response) => {
        dispatch(setImeiList(response?.data));
        setImei(response?.data[0]);
      })
      .catch((e) => toast.error(e.response?.data?.message));
  }, [authToken, dispatch]);

  const getVehicleDetails = useCallback(async () => {
    await getApis(
      vehicleDetailsApiUrl,
      {
        imei: imei,
      },
      authToken
    )
      .then((response) => {
        dispatch(setVehicleDetails(response?.data));
      })
      .catch((e) => toast.error(e.response?.data?.message));
  }, [authToken, dispatch, imei]);

  useEffect(() => {
    getImeiList();
  }, [getImeiList]);

  useEffect(() => {
    if (imei !== "") {
      getVehicleDetails();
    }
  }, [getVehicleDetails, imei]);

  const onChangeImei = (value) => {
    setImei(value);
    dispatch(setVehicleDetails({}));
    dispatch(setConnectivityStart({}));
    dispatch(setConnectivityStop({}));
  };

  const onChangeTab = (key) => {
    dispatch(setSelectedTab(key));
  };

  const conStartTest = async () => {
    const currentTime = Math.floor(Date.now());
    await postApis(
      vehicleTestStartApiUrl,
      {
        imei: vehicleDetails?.imeiNumber,
        startTime: currentTime,
        type: "CONNECTIVITY",
      },
      authToken
    )
      .then((response) => {
        dispatch(setConnectivityStart(response?.data));
        dispatch(setTestRunning(true));
      })
      .catch((e) => toast.error(e.response?.data?.message));
  };

  const conStopTest = async () => {
    const currentTime = Math.floor(Date.now());
    await postApis(
      vehicleTestStopApiUrl,
      {
        imei: vehicleDetails?.imeiNumber,
        stopTime: currentTime,
        testId:
          connectivityStart?.testId ||
          vehicleDetails?.previousTests?.connectivity,
        type: "CONNECTIVITY",
      },
      authToken
    )
      .then((response) => {
        dispatch(setConnectivityStop(response?.data));
        dispatch(setTestRunning(false));
        showCommentModal();
      })
      .catch((e) => toast.error(e.response?.data?.message));
  };

  const dynoStartTest = async () => {
    const currentTime = Math.floor(Date.now());
    await postApis(
      vehicleTestStartApiUrl,
      {
        imei: vehicleDetails?.imeiNumber,
        startTime: currentTime,
        type: "DYNO",
      },
      authToken
    )
      .then((response) => {
        dispatch(setDynoStart(response?.data));
        dispatch(setTestRunning(true));
        dispatch(setDynoStop({}));
      })
      .catch((e) => toast.error(e.response?.data?.message));
  };

  const dynoStopTest = async () => {
    const currentTime = Math.floor(Date.now());
    await postApis(
      vehicleTestStopApiUrl,
      {
        imei: vehicleDetails?.imeiNumber,
        stopTime: currentTime,
        testId: dynoResult?.testId || vehicleDetails?.previousTests?.dyno,
        type: "DYNO",
      },
      authToken
    )
      .then((response) => {
        dispatch(setDynoStop(response?.data));
        dispatch(setTestRunning(false));
        showCommentModal();
      })
      .catch((e) => toast.error(e.response?.data?.message));
  };

  const items = [
    {
      key: "heartBeat",
      label: `Heart Beat`,
      children: (
        <Connectivity
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          onChangeImei={onChangeImei}
          imei={imei}
        />
      ),
    },
    {
      key: "dyno",
      label: `Dyno`,
      children: (
        <Dyno
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          onChangeImei={onChangeImei}
          imei={imei}
        />
      ),
    },
  ];

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Tabs
          defaultActiveKey={selectedTab}
          items={items}
          onChange={onChangeTab}
          tabBarExtraContent={
            selectedTab === "dyno" ? (
              <DynoStartTest
                stopTest={dynoStopTest}
                startTest={dynoStartTest}
              />
            ) : (
              <ConnectivityStartTest
                stopTest={conStopTest}
                startTest={conStartTest}
              />
            )
          }
        />
      </Col>
    </Row>
  );
};

export default EolTests;
