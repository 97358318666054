import { Modal, Spin } from "antd";

const Loader = ({ text }) => {
  return (
    <Modal
      open={true}
      footer={null}
      closeIcon={false}
      className="text-center"
      width={180}
    >
      {text ? text : "Loading"}
      <Spin size="small" className="ml05" />
    </Modal>
  );
};
export default Loader;
