import "./styles.css";
import SingleSummary from "./SingleSummary";
import { Col, Row } from "antd";
import { useState } from "react";

function CumulativeSummary (){
  const [summaryList, setSummaryList] = useState([1]);
  const [tripSummary, setTripSummary] = useState({});

  const updateSummaryList = (data) => {
    setSummaryList(data);
  }

  const updateTripSummary = (data) => {
    setTripSummary(data);
  }

  return (
    <Row gutter={[16, 16]} className="test-ride-statistic">
      {summaryList.map((data) => (
        <Col sm={24} key={data} md={summaryList.length >= 2 ? 12 : 24} className="summary-col">
          <SingleSummary data={data} summaryList={summaryList} tripSummary={tripSummary} updateSummaryList={updateSummaryList} updateTripSummary={updateTripSummary} />
        </Col>
      ))}
    </Row>
  );
};

export default CumulativeSummary;