import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import {
  MOTOR_STATUS_TEMP_BAR_CHART,
  MOTOR_STATUS_TEMP_BOX_CHART,
  MOTOR_STATUS_TEMP_CANDLESTICK_CHART,
  MOTOR_STATUS_TEMP_LINE_CHART,
} from "../../Common/Grafana";
import Iframe from "../../Common/Iframe";

const Panels = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const filter = useSelector((state) => state.filter);
  const motorField = filter?.motorField;
  const fleet = filter?.fleet;
  const imei = filter?.imei;

  return (
    <Row className="mt2">
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Row>
              <Col span={24}>
                {fleet && imei && (
                  <Iframe
                    title="Generic Trend"
                    src={MOTOR_STATUS_TEMP_LINE_CHART(
                      userDetails?.currentOrganisation?.id,
                      fleet,
                      imei,
                      motorField
                    )}
                    width="100%"
                    height="260"
                  />
                )}
              </Col>
            </Row>
            <Row className="mt05">
              <Col span={24}>
                {fleet && imei && (
                  <Iframe
                    title="Histogram"
                    src={MOTOR_STATUS_TEMP_BAR_CHART(
                      userDetails?.currentOrganisation?.id,
                      fleet,
                      imei,
                      motorField
                    )}
                    width="100%"
                    height="260"
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {fleet && imei && (
              <Iframe
                title="Box Plot"
                src={MOTOR_STATUS_TEMP_BOX_CHART(
                  userDetails?.currentOrganisation?.id,
                  fleet,
                  imei,
                  motorField
                )}
                width="100%"
                height="535"
              />
            )}
          </Col>
        </Row>
        <Row className="mt05">
          <Col span={24}>
            {fleet && imei && (
              <Iframe
                title="CandleStick"
                src={MOTOR_STATUS_TEMP_CANDLESTICK_CHART(
                  userDetails?.currentOrganisation?.id,
                  fleet,
                  imei,
                  motorField
                )}
                width="100%"
                height="260"
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Panels;
