import { Select, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFleetOptions,
  selectVehicleOptions,
  setFleet,
  setImei,
} from "../../../reducers/filterSlice";

const Filters = () => {
  const fleetOptions = useSelector(selectFleetOptions);
  const vehicleOptions = useSelector(selectVehicleOptions);
  const filter = useSelector((state) => state.filter);
  const fleet = filter.fleet;
  const imei = filter.imei;
  const dispatch = useDispatch();

  const onChangeFleet = (value) => {
    dispatch(setFleet(value));
    dispatch(setImei(vehicleOptions[value][0]));
  };

  const onChangeImei = (value) => {
    dispatch(setImei(value));
  };

  const imeiFilterOprions = vehicleOptions[fleet];
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <Select
          defaultValue={fleet}
          style={{ width: "100%" }}
          onChange={onChangeFleet}
          options={fleetOptions.map((f) => ({ label: f, value: f }))}
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <Select
          value={imei}
          style={{ width: "100%" }}
          onChange={onChangeImei}
          options={imeiFilterOprions.map((i) => ({
            label: i,
            value: i,
          }))}
        />
      </Col>
    </Row>
  );
};

export default Filters;
