import { Col, Row } from "antd";
import VehicleDetails from "../../VehicleDetails";
import List from "./List";

const RideHistory = ({ onChangeImei, imei }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={5}>
        <VehicleDetails onChangeImei={onChangeImei} imei={imei} />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={19}>
        <List />
      </Col>
    </Row>
  );
};

export default RideHistory;
