import { Card, Col, Row, Table } from "antd";
import ECO from "../../../assets/images/icons/eco.svg";
import CITY from "../../../assets/images/icons/city.svg";
import POWER from "../../../assets/images/icons/power.svg";
import OVERALL from "../../../assets/images/icons/overall.svg";
import SPEED from "../../../assets/images/icons/speed.svg";
import { UNITS } from "../../Common/Constants";
import TripHighlight from "./TripHighlight";
import ChordChart from "./ChordChart";

const SpeedMap = ({ summaryList, tripSummary }) => {
  const summaryCount = summaryList?.length;

  const overallRowData = tripSummary?.speedModeData?.filter(
    (item) => item.fieldName === "average"
  );
  const excludedOverallData = tripSummary?.speedModeData?.filter(
    (item) => item.fieldName !== "average"
  );

  const renderIcons = (mode) => {
    switch (mode) {
      case 0:
        return <img src={CITY} alt="CITY" className="mr05" />;
      case 1:
        return <img src={ECO} alt="ECO" className="mr05" />;
      case 2:
        return <img src={POWER} alt="POWER" className="mr05" />;
      default:
        return "";
    }
  };

  const columns = [
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
      render: (_, record, index) => {
        return (
          <div className="left">
            <div className="flex-center">
              {renderIcons(index)}
              {record.fieldName}
            </div>
          </div>
        );
      },
    },
    {
      title: "Frequent Speed",
      dataIndex: "speed",
      key: "speed",
      render: (_, record) => {
        return `${record.frequentSpeed} ${UNITS.KM_PER_HR}`;
      },
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
      render: (_, record) => {
        return `${record.distance} ${UNITS.KM}`;
      },
    },
  ];

  const overallImageText = () => {
    return (
      <div className="flex-center">
        <img src={OVERALL} alt="OVERALL" className="mr05" /> OVERALL
      </div>
    );
  };

  return (
    <Row gutter={[16, 16]} className="mt1">
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={summaryCount > 1 ? 24 : 8}
        xl={summaryCount > 1 ? 24 : 8}
      >
        <Row>
          <Col span={24}>
            <Card
              title={
                <div className="fs14 left">
                  <div className="flex-center">
                    <img src={SPEED} alt="km" className="icon-size mr05" />
                    Mode Speed And Distance of All Trips
                  </div>
                </div>
              }
              bodyStyle={{ padding: "1px 1px" }}
              actions={
                overallRowData && [
                  overallImageText(),
                  `${overallRowData[0]?.frequentSpeed.toFixed(1)} ${
                    UNITS.KM_PER_HR
                  }`,
                  `${overallRowData[0]?.distance?.toFixed(1)} ${UNITS.KM}`,
                ]
              }
            >
              <Table
                className="mode-table"
                rowKey={(record) => record.mode}
                columns={columns}
                dataSource={excludedOverallData}
                pagination={false}
                scroll={{ x: "100%" }}
              />
            </Card>
          </Col>
        </Row>
        <Row className="mt075">
          <Col span={24}>
            <TripHighlight
              summaryList={summaryList}
              tripSummary={tripSummary}
            />
          </Col>
        </Row>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={summaryCount > 1 ? 24 : 16}
        xl={summaryCount > 1 ? 24 : 16}
      >
        <div className="wheel-chart-col">
          <ChordChart
            summaryList={summaryList}
            tripSummary={tripSummary}
          />
        </div>
      </Col>
    </Row>
  );
};

export default SpeedMap;
