import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Result from "./Panels/Result";
import History from "./Panels/History";
import { vehicleTestResultApiUrl } from "../../../../Common/endpoints";
import { getApis } from "../../../../Common/apis";
import {
  setDynoResult,
  setTestRunning,
} from "../../../../../reducers/eolSlice";
import { useCallback, useEffect } from "react";
import Comment from "../Comment";

const Dyno = ({ isModalOpen, handleCloseModal, onChangeImei, imei }) => {
  const dispatch = useDispatch();
  const eol = useSelector((state) => state.eol);
  const testRunning = eol?.testRunning;
  const vehicleDetails = eol?.vehicleDetails;
  const dynoStart = eol?.dynoStart;
  const dynoResult = eol?.dynoResult;
  const selectedTab = eol?.selectedTab;
  const authToken = localStorage.getItem("authToken");

  const dynoResultApi = useCallback(async () => {
    const testId = dynoStart?.testId
      ? dynoStart?.testId
      : vehicleDetails?.previousTests?.dyno;
    if (testId) {
      const response = await getApis(
        vehicleTestResultApiUrl,
        {
          testId: testId,
          imei: vehicleDetails?.imeiNumber,
        },
        authToken
      );
      dispatch(setDynoResult(response?.data));
      dispatch(setTestRunning(response?.data?.status === "RUNNING"));
    }
  }, [
    authToken,
    dispatch,
    dynoStart?.testId,
    vehicleDetails?.imeiNumber,
    vehicleDetails?.previousTests?.dyno,
  ]);

  useEffect(() => {
    let intervalId;

    if (testRunning) {
      intervalId = setInterval(() => {
        dynoResultApi();
      }, 5000);
    } else if (vehicleDetails?.previousTests?.dyno && selectedTab === "dyno") {
      dynoResultApi();
    }

    return () => clearInterval(intervalId);
  }, [
    testRunning,
    dynoResultApi,
    vehicleDetails?.previousTests?.dyno,
    selectedTab,
  ]);

  return (
    <Row>
      <Comment
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        testId={dynoResult?.testId}
        formName={selectedTab}
      />
      <Col span={24}>
        {testRunning === true ? (
          <Result onChangeImei={onChangeImei} imei={imei} />
        ) : (
          <History onChangeImei={onChangeImei} imei={imei} />
        )}
      </Col>
    </Row>
  );
};

export default Dyno;
