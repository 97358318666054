const Iframe = ({ title, src, width, height, frameBorder, className }) => {
  return (
    <iframe
      title={title}
      src={src}
      width={width}
      height={height}
      frameBorder={frameBorder ? frameBorder : "0"}
      className={className ? `${className} box-shadow` : "box-shadow"}
    ></iframe>
  );
};

export default Iframe;
