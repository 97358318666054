import React from "react";
import { Row, Col, Card, Steps } from "antd";
import "./styles.css";
import RiderDetailsForm from "./RiderForm";
import VehicleDetailsForm from "./VehicleForm";
import RideDetailsForm from "./RideForm";
import { useSelector } from "react-redux";
import { TEST_RIDE } from "../../../Common/Constants";
import VehicleDetails from "../../VehicleDetails";

const stepItems = [
  {
    title: TEST_RIDE.RIDER_DETAILS,
  },
  {
    title: TEST_RIDE.RIDE_DETAILS,
  },
  {
    title: TEST_RIDE.VEHICLE_DETAILS,
  },
];

const AddTestRide = ({ getRideHistoryData, onChangeImei, imei }) => {
  const currentStep = useSelector((state) => state.testRide)?.currentStep;

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={5}>
        <VehicleDetails onChangeImei={onChangeImei} imei={imei} />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={19}>
        <Card bordered={false} className="test-ride-form">
          <Row justify={"center"}>
            <Col span={24} lg={{ span: 20 }} xxl={{ span: 16 }}>
              <Steps
                size="large"
                current={currentStep}
                items={stepItems}
                style={{ padding: "20px 0px 40px" }}
              />
            </Col>
          </Row>

          <Row justify={"center"}>
            <Col
              span={24}
              lg={{ span: 20 }}
              xl={{ span: 14 }}
              xxl={{ span: 10 }}
            >
              {currentStep === 0 ? (
                <RiderDetailsForm />
              ) : currentStep === 1 ? (
                <RideDetailsForm />
              ) : (
                <VehicleDetailsForm getRideHistoryData={getRideHistoryData} />
              )}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default AddTestRide;
