import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import {
  DRIVER_BEHAVIOUR_SCATTER_CHART,
  DRIVER_BEHAVIOUR_X_BOX_CHART,
  DRIVER_BEHAVIOUR_Y_BOX_CHART,
  DRIVER_BEHAVIOUR_Z_BOX_CHART,
} from "../../Common/Grafana";
import Iframe from "../../Common/Iframe";

const Panels = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const filter = useSelector((state) => state.filter);
  const fleet = filter?.fleet;
  const imei = filter?.imei;

  return (
    <Row className="mt2">
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {fleet && imei && (
              <Iframe
                title="TimeSeries"
                src={DRIVER_BEHAVIOUR_SCATTER_CHART(
                  userDetails?.currentOrganisation?.id,
                  fleet,
                  imei
                )}
                width="100%"
                height="400"
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {fleet && imei && (
              <Iframe
                title="BoxPlot"
                src={DRIVER_BEHAVIOUR_X_BOX_CHART(
                  userDetails?.currentOrganisation?.id,
                  fleet,
                  imei
                )}
                width="100%"
                height="400"
              />
            )}
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="mt1">
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {fleet && imei && (
              <Iframe
                title="Histogram"
                src={DRIVER_BEHAVIOUR_Y_BOX_CHART(
                  userDetails?.currentOrganisation?.id,
                  fleet,
                  imei
                )}
                width="100%"
                height="400"
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {fleet && imei && (
              <Iframe
                title="Histogram"
                src={DRIVER_BEHAVIOUR_Z_BOX_CHART(
                  userDetails?.currentOrganisation?.id,
                  fleet,
                  imei
                )}
                width="100%"
                height="400"
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Panels;
