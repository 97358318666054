import { NicheJwtLogin } from "niche-ui-component";
import { userJwtLoginSchemaUrl, userJwtLoginUrl } from "../Common/endpoints";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "../../reducers/authSlice";
import "./style.css";
import { setUserDetails } from "../Common/Utils";
import { useEffect } from "react";
import { useOrganization } from "../../OrganizationProvider";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organization = useOrganization();

  useEffect(() => {
    document.title = organization?.name;
  }, [organization]);

  const handleSubmit = (response) => {
    dispatch(setLoggedIn());
    setUserDetails(response?.data);
    navigate("/");
  };

  return (
    <Row>
      <Col xs={0} sm={0} md={0} lg={12} xl={12}>
        <div className="banner"></div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Row className="mt3">
          <Col span={10} offset={4}>
            <img
              src={`${organization.s3BucketBaseUrl}/OuterLogo.svg`}
              alt="Logo"
            />
          </Col>
        </Row>
        <Row justify="center" className="mt4">
          <Col xs={20} sm={20} md={16} lg={16} xl={16}>
            <Row>
              <Col className="h1-header">Login to your account</Col>
            </Row>
            <Row>
              <Col className="sub-header mt-space">Welcome back!</Col>
            </Row>
            <Row className="mt2 mb2">
              <Col>
                <NicheJwtLogin
                  handleSubmit={handleSubmit}
                  url={userJwtLoginUrl}
                  schemaUrl={userJwtLoginSchemaUrl}
                  linkText="Forgot Password?"
                  linkUrl="/forgotPassword"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;
